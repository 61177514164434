import Vue from 'vue';
import Vuex from 'vuex';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import moment from 'moment';
import * as queries from './graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { eventBus } from "./main.js";
import * as subscriptions from './graphql/subscriptions';

import { initializeLambdaClient } from "@/utilities/SessionUtils";
import { InvokeCommand } from "@aws-sdk/client-lambda";
//import router from './router';
// import Amplify from 'aws-amplify';

//import {Animal } from "@/models";


//var TimestreamQuery = require("aws-sdk/clients/timestreamquery");


Auth.currentUserInfo

var sem_status = {};

var binArrayToJson = function (binArray) {
  var str = "";
  for (var i = 0; i < binArray.length; i++) {
    str += String.fromCharCode(parseInt(binArray[i]));
  }
  return JSON.parse(str)
}

var dam_sires = function (all_animals) {
  var dams = [];
  var sires = [];
  var animals = {};
  if (all_animals.length > 0) {
    for (const i in all_animals) {
      if (all_animals[i].animal_sex == "FEMALE") {
        dams.push({
          value: all_animals[i].id,
          color: all_animals[i].tags.items[0]
            ? all_animals[i].tags.items[0].animal_tag_color
            : "",
          animal_image_head:
            all_animals[i].animal_image_head,
          text: all_animals[i].tags.items[0]
            ? all_animals[i].tags.items[0].animal_tag_text
            : "",
        });
      } else if (all_animals[i].animal_sex == "MALE") {
        sires.push({
          color: all_animals[i].tags.items[0]
            ? all_animals[i].tags.items[0].animal_tag_color
            : "",
          animal_image_head:
            all_animals[i].animal_image_head,
          value: all_animals[i].id,
          text: all_animals[i].tags.items[0]
            ? all_animals[i].tags.items[0].animal_tag_text
            : "",
        });
      }
      animals[all_animals[i].id] = {
        tag: all_animals[i].tags.items[0],
        animal_image_head:
          all_animals[i].animal_image_head,
      };
    }
  }
  return [
    dams.sort((a, b) => {
      if (!a && !a.text && !b && !b.text) {
        return -1;
      }

      var reA = /[^a-zA-Z]/g;
      var reN = /[^0-9]/g;

      var aA = a.text.replace(reA, "").toLowerCase();
      var bA = b.text.replace(reA, "").toLowerCase();
      if (aA === bA) {
        var aN = parseInt(a.text.replace(reN, ""), 10);
        var bN = parseInt(b.text.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    }),
    sires.sort((a, b) => {
      if (!a && !a.text && !b && !b.text) {
        return -1;
      }

      var reA = /[^a-zA-Z]/g;
      var reN = /[^0-9]/g;

      var aA = a.text.replace(reA, "").toLowerCase();
      var bA = b.text.replace(reA, "").toLowerCase();
      if (aA === bA) {
        var aN = parseInt(a.text.replace(reN, ""), 10);
        var bN = parseInt(b.text.replace(reN, ""), 10);
        return aN === bN ? 0 : aN > bN ? 1 : -1;
      } else {
        return aA > bA ? 1 : -1;
      }
    }),
    animals,
  ];
}
//var timestreamQueryClient;

Vue.use(Vuex);


function sort_by_key(array, key) {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    if (x == null) {
      x = "zzz";
    }
    if (y == null) {
      y = "zzz";
    }

    return x < y ? -1 : x > y ? 1 : 0;
  });
}
//#endregion
function sort_by_key_elanco_trial(array) {
  return array.sort(function (a, b) {
    var x = (a.Data.length && a.Data[0].ScalarValue) ? parseInt(a.Data[0].ScalarValue.split('-')[1]) : null;
    var y = (b.Data.length && b.Data[0].ScalarValue) ? parseInt(b.Data[0].ScalarValue.split('-')[1]) : null;
    if (x == null) {
      x = "zzz";
    }
    if (y == null) {
      y = "zzz";
    }

    return x < y ? -1 : x > y ? 1 : 0;
  });
}

const weatherService = {

  state: {

    error: null,
    loading: false,
    // current
    currentWeather: Object,
    currentTemp: '',
    minTemp: '',
    maxTemp: '',
    feelsLike: '',
    sunrise: '',
    sunset: '',
    pressure: '',
    humidity: '',
    wind: '',
    overcast: '',
    name: '',
    UV: '',
    visibility: '',
    wind_speed: '',
    wind_degree: '',
    wind_gust: '',
    clouds: '',
    latitude: '',
    longitude: '',
    hourlyHours: [],
    current: Object,
    daily: Object,
    hourly: Object
  },
  mutations: {
    SET_WEATHER_DATA(state, payload) {

      // current

      //state.current = payload.current
      state.currentTemp = payload.main.temp;
      state.currentWeather = payload.weather[0].description;
      state.sunrise = new Date(payload.sys.sunrise * 1000)
        .toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
        });
      state.sunset = new Date(payload.sys.sunset * 1000).toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
      });
      state.feelsLike = payload.main.feels_like + '°C'
      state.humidity = payload.main.humidity + '%';
      //state.UV = payload.current.uvi;
      state.clouds = payload.clouds.all + '%'
      state.visibility = payload.visibility / 1000 + ' km'
      state.wind_speed = (payload.wind.speed * 3.6).toPrecision(2) + ' km/h';
      state.wind_degree = payload.wind.deg + '°'
      // daily
      state.daily = payload.daily
      // hourly
      state.hourly = payload.hourly
    },
  },
  actions: {
    GET_WEATHER_DATA({ commit, state }, site_coord) {

      axios
        .get(
          'https://api.openweathermap.org/data/2.5/weather?lat=' +
          site_coord.latitude +
          '&lon=' +
          site_coord.longitude +
          '&exclude=minutely' +
          '&units=metric&appid=' +
          '9fffbcda9b424a299c3f2b549a4b8679'
        )
        .then((response) => {
          commit('SET_WEATHER_DATA', response.data)
          state.loading = false
        })
    }
  },
  getters: {
    weatherGetter(state) {
      return state;
    },
  }
};

const store = new Vuex.Store({
  modules: {
    w: weatherService
  },
  state: {
    lambda_client: null,
    authorized: false,
    user: null,
    user_ddb: { customer: false, collaborator_companies: [] },
    currentTemp: '',
    loginError: '',
    signupError: '',
    latitude: '',
    longitude: '',
    coordinates: Object,
    button: Boolean,
    // loadedCattle: [],
    ddbGetData: [],
    cameras: [],
    cam_types: [],
    cam_names: [],
    video_list: [],
    thumbnails: [],
    alerts: [],
    notices: [],
    doneFetching: false,
    jetson: '',
    site: [],
    camera_objects: [],
    wifi_object: [],
    selected_camera: null,
    events: null,
    animals: [],
    herds: [],
    groups: [],
    last_footage: [],
    medical_records: [],
    carcasses: [],
    measurements: [],
    phenotypes: [],
    animal: null,
    animal_stats: null,
    notes: [],
    filtered_animals: [],
    animal_groups: [],
    zones: [],
    most_recent_animals: [],
    calendar_events: [],
    calves: [],
    cows: [],
    bulls: [],
    scrotum_circumference: [],
    sperm_morphology: [],
    phenotype_charts: false,
    measurement_charts: false,
    video_query_month: null,
    video_loading: true,
    loading_model: "Initializing",
    card_events: [],
    event_params: {},
    lambda_auth_token: null,
    lambda_auth_token_home: null,
    jwt_token: null,
    customer_id: null,
    allowed_operations: 'All',
    videos_at_dates: [],
    enums: {},
    images: [],
    bbox_color_code: {
      "head": {
        fillStyle: "rgba(0, 154, 255, 0.4)",
        strokeStyle: "rgba(0, 154, 255)"
      },
      "tag": {
        fillStyle: "rgba(55, 255, 255, 0.4)",
        strokeStyle: "rgba(55, 255, 255)"
      },
      "tail": {
        fillStyle: "rgba(255, 66, 161, 0.4)",
        strokeStyle: "rgba(255, 66, 161)"
      },
      "car": {
        fillStyle: "rgba(186, 86, 36, 0.4)",
        strokeStyle: "rgba(186, 86, 36)"
      },
      "cat": {
        fillStyle: "rgba(92, 103, 132, 0.4)",
        strokeStyle: "rgba(92, 103, 132)"
      },
      "cow": {
        fillStyle: "rgba(60, 179, 113, 0.4)",
        strokeStyle: "rgba(60, 179, 113)"
      },
      "dog": {
        fillStyle: "rgba(32, 164, 243, 0.4)",
        strokeStyle: "rgba(32, 164, 243)"
      },
      "horse": {
        fillStyle: "rgba(148, 123, 211, 0.4)",
        strokeStyle: "rgba(148, 123, 211)"
      },
      "person": {
        fillStyle: "rgba(240, 167, 160, 0.4)",
        strokeStyle: "rgba(240, 167, 160)"
      },
      "pig": {
        fillStyle: "rgba(96, 73, 90, 0.4)",
        strokeStyle: "rgba(96, 73, 90)"
      },
      "sheep": {
        fillStyle: "rgba(255, 87, 187, 0.4)",
        strokeStyle: "rgba(255, 87, 187)"
      },
      "truck": {
        fillStyle: "rgba(140, 39, 30, 0.4)",
        strokeStyle: "rgba(140, 39, 30)"
      },
      "coyote": {
        fillStyle: "rgba(247, 238, 127, 0.4)",
        strokeStyle: "rgba(247, 238, 127)"
      },
      "bear": {
        fillStyle: "rgba(164, 180, 148, 0.4)",
        strokeStyle: "rgba(164, 180, 148)"
      },
      "bison": {
        fillStyle: "rgba(196, 167, 125, 0.4)",
        strokeStyle: "rgba(196, 167, 125)"
      },
    },

    analytics_predators_by_pgie_count: [],
    analytics_contractions_by_pgie_count: [],
    analytics_animals_by_pgie_count: [],
    analytics_animal_image_by_pgie_count: [],
    analytics_animal_image_count: [],
    analytics_animal_count: [],
    analytics_contraction: [],
    analytics_predator: [],


    pending_animal_identifications: [],

    dams_sires: [[], [], {}],

    scratching_trial: { human: [], betsy: [] },

    home_scratching_graphs: { t1human: [], t1betsy: [], t2betsy: [] },

    camera_trials_dog_total: { human: [], betsy: [] }
  },
  mutations: {
    SET_CAMERA_TRIALS_DOG_TOTAL(state, payload) {
      state.camera_trials_dog_total = payload
    },
    SET_HOME_SCRATCHING_GRAPHS(state, payload) {
      state.home_scratching_graphs = payload
    },
    SET_SRATCHING_TRIAL(state, payload) {
      state.scratching_trial = payload
    },
    SET_DAM_SIRES(state, payload) {
      state.dams_sires = payload
    },
    SET_PENDING_ANIMAL_IDENTIFICATIONS(state, payload) {
      state.pending_animal_identifications = payload
    },
    SET_ANALYTICS_PREDATORS_BY_PGIE_COUNT(state, payload) {
      state.analytics_predators_by_pgie_count = payload
    },
    SET_ANALYTICS_CONTRACTIONS_BY_PGIE_COUNT(state, payload) {
      state.analytics_contractions_by_pgie_count = payload
    },
    SET_ANALYTICS_ANIMALS_BY_PGIE_COUNT(state, payload) {
      state.analytics_animals_by_pgie_count = payload
    },
    SET_ANALYTICS_ANIMAL_IMAGE_BY_PGIE_COUNT(state, payload) {
      state.analytics_animal_image_by_pgie_count = payload
    },
    SET_ANALYTICS_CONTRACTIONS(state, payload) {
      state.analytics_contraction = payload
    },
    SET_ANALYTICS_PREDATORS(state, payload) {
      state.analytics_predator = payload
    },
    SET_ANALYTICS_ANIMAL_COUNT(state, payload) {
      state.analytics_animal_count = payload
    },
    SET_ANALYTICS_ANIMAL_IMAGE_COUNT(state, payload) {
      state.analytics_animal_image_count = payload
    },
    SET_LOGIN_ERROR(state, payload) {
      state.loginError = payload
    },
    SET_VIDEOS_AT_DATES(state, payload) {
      state.videos_at_dates = payload
    },
    SET_ALLOWED_OPERATIONS(state, payload) {
      state.allowed_operations = payload
    },
    SET_CUSTOMER_ID(state, payload) {
      state.customer_id = payload
    },
    SET_JWT_TOKEN(state, payload) {
      state.jwt_token = payload
    },
    SET_LAMBDA_AUTH_TOKEN_HOME(state, payload) {
      state.lambda_auth_token_home = payload
    },
    SET_LAMBDA_AUTH_TOKEN(state, payload) {
      state.lambda_auth_token = payload
    },
    SET_MEASUREMENT_CHART(state, payload) {
      state.measurement_charts = payload
    },
    SET_PHENOTYPE_CHART(state, payload) {
      state.phenotype_charts = payload
    },
    SET_SCROTUM_CIRCUMFERENCE(state, payload) {
      state.scrotum_circumference = payload
    },
    SET_SPERM_MORPHOLOGY(state, payload) {
      state.sperm_morphology = payload
    },
    SET_BULLS(state, payload) {
      state.bulls = payload
    },
    SET_COWS(state, payload) {
      state.cows = payload
    },
    SET_CALVES(state, payload) {
      state.calves = payload
    },
    SET_CALENDAR_EVENTS(state, payload) {
      state.calendar_events = payload
    },
    SET_MOST_RECENT_ANIMALS(state, payload) {
      state.most_recent_animals = payload
    },
    SET_ANIMAL_GROUPS(state, payload) {
      state.animal_groups = payload
    },
    SET_GROUPS(state, payload) {
      state.groups = payload
    },
    SET_NOTES(state, payload) {
      state.notes = payload
    },
    SET_ANIMAL(state, payload) {
      state.animal = payload
    },
    SET_CARCASSES(state, payload) {
      state.carcasses = payload
    },
    SET_MEASUREMENTS(state, payload) {
      state.measurements = payload
    },
    SET_PHENOTYPES(state, payload) {
      state.phenotypes = payload
    },
    SET_MEDICAL_RECORDS(state, payload) {
      state.medical_records = payload
    },
    SET_LAST_FOOTAGE(state, payload) {
      state.last_footage = payload
    },
    SET_HERDS(state, payload) {
      state.herds = payload
    },
    SET_ANIMALS(state, payload) {
      state.animals = payload
    },
    SET_FILTERED_ANIMALS(state, payload) {
      state.filtered_animals = payload
    },
    SET_WIFI_OBJECTS(state, payload) {
      state.wifi_object = payload
    },
    SET_CAMERA_OBJECTS(state, payload) {
      state.camera_objects = payload
    },
    SET_SELECTED_CAMERA(state, payload) {
      state.selected_camera = payload
    },
    SET_COORDINATES(state, payload) {
      state.coordinates = payload
    },
    SET_CAMERAS(state, payload) {
      state.cameras = payload
    },
    SET_SITE(state, payload) {
      state.site = payload
    },
    SET_CAMERA_TYPES(state, payload) {
      state.cam_types = payload
    },
    SET_CAMERA_NAMES(state, payload) {
      state.cam_names = payload
    },
    SET_VIDEO_LIST(state, payload) {
      state.video_list = payload
    },
    SET_USER_DATA(state, payload) {
      state.user_ddb = payload
    },

    SET_THUMBNAILS(state, payload) {
      state.thumbnails = payload
    },
    SET_EVENTS(state, payload) {
      state.events = payload
    },
    SET_CARD_EVENTS(state, payload) {
      state.card_events = payload
    },
    SET_ALERTS(state, payload) {
      state.alerts = payload
    },
    SET_NOTICES(state, payload) {
      state.notices = payload
    },
    SET_JETSON(state, payload) {
      state.jetson = payload
    },
    SET_ANIMAL_STATS(state, payload) {
      state.animal_stats = payload
    },
    SET_ZONES(state, payload) {
      state.zones = payload
    },
    SET_ENUMS(state, payload) {
      state.enums = payload
    },
    SET_IMAGES(state, payload) {
      state.images = payload
    },
    user(state, user) {
      // state.authorized = !!user && user.attributes && user.attributes.email_verified
      state.authorized = !!user && user.attributes
      state.user = user
    },
  },

  actions: {
    DDB_GET_CAMERA_TRIALS_DOG_TOTAL({ commit, state }) {
      return new Promise((resolve) => {
        async function get_data(table) {
          var database_name = process.env.VUE_APP_TIMESTREAM_DB;
          var trial = []

          var query = `
            SELECT 
              "Animal ID", count(bin(time, 1hour)) AS time
            FROM "${database_name}"."${table}"
            WHERE 
              time BETWEEN timestamp '2022-04-05 11:00:00'
                AND timestamp '2023-02-17 10:00:00'
              GROUP BY "Animal ID"
              ORDER BY time ASC
        `;
          var lambda_params = {
            FunctionName: process.env.VUE_APP_TIMESTREAM_LAMBDA /* required */,
            InvocationType: "RequestResponse",

            Payload: JSON.stringify({
              auth_token: state.jwt_token,
              owner_claim: state.customer_id,
              timestream_query: query,
              timestream_next_token: null,
            }),
          };
          var command = new InvokeCommand(lambda_params);
          var data = await state.lambda_client.send(command)
          let return_data = binArrayToJson(data.Payload).timestream_response ? binArrayToJson(data.Payload).timestream_response.response : []
          // return_data.shift()
          trial = sort_by_key_elanco_trial(return_data)
          return trial
        }
        async function get_all_data() {
          var human = await get_data("annotation")
          var betsy = await get_data("scratching_1min")
          commit("SET_CAMERA_TRIALS_DOG_TOTAL", { human: human, betsy: betsy });
          resolve()
        }
        get_all_data()
      })
    },
    DDB_GET_TRIALS_DOG_TOTAL({ commit, state }, params) {
      return new Promise((resolve) => {
        async function get_data(table, dates) {
          var database_name = process.env.VUE_APP_TIMESTREAM_DB;
          var trial = []
          let bin_size = !params.bin_size ? 'time' : `bin(time, ${params.bin_size})`
          for (const i in dates) {
            var query = `
            SELECT 
               ${bin_size} AS time, 
              Sum(CASE 
                WHEN Score > 0
                ${params.max_conf_cutoff && table != "annotation" ? `AND "Maximum Confidence Score" > ${params.max_conf_cutoff}` : ''}
                THEN Score
                ELSE 0
                END) AS Score
            FROM "${database_name}"."${table}"
            WHERE 
              time BETWEEN timestamp '${dates[i][0]}'
                AND timestamp '${dates[i][1]}'
              GROUP BY  ${bin_size}
              ORDER BY time ASC
        `;
            var lambda_params = {
              FunctionName: process.env.VUE_APP_TIMESTREAM_LAMBDA /* required */,
              InvocationType: "RequestResponse",

              Payload: JSON.stringify({
                auth_token: state.jwt_token,
                owner_claim: state.customer_id,
                timestream_query: query,
                timestream_next_token: null,
              }),
            };
            var command = new InvokeCommand(lambda_params);
            var data = await state.lambda_client.send(command)
            let return_data = binArrayToJson(data.Payload).timestream_response ? binArrayToJson(data.Payload).timestream_response.response : []
            // return_data.shift()
            trial[i] = sort_by_key_elanco_trial(return_data)

          }
          return trial
        }
        async function get_all_data() {
          var t1human = await get_data("annotation", [
            ["2022-04-05 11:00:00", "2022-05-10 10:00:00"],
            ["2022-05-10 11:00:00", "2022-06-28 10:00:00"],
            ["2022-06-28 11:00:00", "2022-07-30 10:00:00"],
          ])
          var t1betsy = await get_data("scratching_1min", [
            ["2022-04-05 11:00:00", "2022-05-10 10:00:00"],
            ["2022-05-10 11:00:00", "2022-06-28 10:00:00"],
            ["2022-06-28 11:00:00", "2022-07-30 10:00:00"],
          ])
          var t2betsy = await get_data("scratching_1min", [
            ["2022-11-21 11:00:00", "2022-12-06 10:00:00"],
            ["2022-12-05 11:00:00", "2023-01-17 10:00:00"],
            ["2023-01-17 11:00:00", "2023-02-17 10:00:00"],
          ])
          commit("SET_HOME_SCRATCHING_GRAPHS", { t1human: t1human, t1betsy: t1betsy, t2betsy: t2betsy });
          resolve()
        }
        get_all_data()
      })
    },
    DDB_GET_TRIALS({ commit, state }, params) {
      return new Promise((resolve) => {
        async function get_data(table) {
          var database_name = process.env.VUE_APP_TIMESTREAM_DB;
          var trial = []
          let bin_size = !params.bin_size ? 'time' : `bin(time, ${params.bin_size})`
          for (const i in params.dates) {
            var query = `
          WITH cte1 AS (
            SELECT 
              "Animal ID", ${bin_size} AS time, 
              Sum(CASE 
                WHEN Score > 0
                ${params.avg_conf_cutoff && table != "annotation" ? `AND "Average Confidence Score" > ${params.avg_conf_cutoff}` : ''}
                ${params.min_conf_cutoff && table != "annotation" ? `AND "Minimum Confidence Score" > ${params.min_conf_cutoff}` : ''}
                ${params.max_conf_cutoff && table != "annotation" ? `AND "Maximum Confidence Score" > ${params.max_conf_cutoff}` : ''}
                THEN Score
                ELSE 0
                END) AS Score
            FROM "${database_name}"."${table}"
            WHERE 
              time BETWEEN timestamp '${params.dates[i][0]}'
                AND timestamp '${params.dates[i][1]}'
              GROUP BY "Animal ID", ${bin_size}
              ORDER BY time ASC
          )
          SELECT "Animal ID", array_agg(time), array_agg(Score) FROM cte1
            GROUP BY "Animal ID"
            ORDER BY "Animal ID" ASC
        `;
            var lambda_params = {
              FunctionName: process.env.VUE_APP_TIMESTREAM_LAMBDA /* required */,
              InvocationType: "RequestResponse",

              Payload: JSON.stringify({
                auth_token: state.jwt_token,
                owner_claim: state.customer_id,
                timestream_query: query,
                timestream_next_token: null,
              }),
            };
            var command = new InvokeCommand(lambda_params);
            var data = await state.lambda_client.send(command)
            let return_data = binArrayToJson(data.Payload).timestream_response ? binArrayToJson(data.Payload).timestream_response.response : []
            // return_data.shift()
            trial[i] = sort_by_key_elanco_trial(return_data)

          }
          return trial
        }

        async function get_all_data() {
          var human = await get_data("annotation")
          var betsy = await get_data("scratching_1min")

          commit("SET_SRATCHING_TRIAL", { human: human, betsy: betsy });
          resolve()
        }
        get_all_data("scratching_1min")

      })
    },
    DDB_GET_PENDING_ANIMAL_IDENTIFICATIONS({ commit, state }) {
      return new Promise((resolve) => {
        async function get_herds() {
          var identifications = []
          identifications = (await API.graphql({
            query: queries.listAnimalTagsByReviewStatus,
            variables: { customer_id: state.customer_id, animal_tag_review_statusAnimal_tag_date: { beginsWith: { animal_tag_review_status: "PENDING" } } },
            authToken: store.state.lambda_auth_token
          })).data
          commit('SET_PENDING_ANIMAL_IDENTIFICATIONS', identifications.listAnimalTagsByReviewStatus.items)
          resolve()
        }
        get_herds()
      })
    },

    DDB_GET_PREDATORS({ commit, state }, params) {
      return new Promise((resolve, reject) => {

        var date_start = `timestamp '${params.date_start}'`
        var date_end = `timestamp '${params.date_end}'`

        var time_bucket = params.bucket

        var frame_area = "frame_area"
        var group_by_cams = params.group_by_cams
        var increments = params.increment_size

        var table_name = params.user_id;
        var database_name = process.env.VUE_APP_TIMESTREAM_DB;

        let select = `count_if(predator = 'Y') AS "predators"`
        let camera_group_by = ``
        let select_final = `"predators"`
        let cast_time = `hour`
        let order_by = `time DESC`

        let camera_selection = `TRUE`
        if (params.camera_id && params.camera_id.length) {
          let cameras = params.camera_id.join(' OR ').replaceAll(' O', "' O").replaceAll('R ', "R camera_id = '")
          camera_selection = `(camera_id = '${cameras}')`
        }
        if (group_by_cams) {
          select = `count_if(predator = 'Y') AS "predators", camera_id AS "camera"`
          camera_group_by = `, camera_id`
          select_final = `"predators", "camera"`
          order_by = `time DESC, camera`
        }
        if (time_bucket.includes('day')) {
          cast_time = `day`
        } else if (time_bucket.includes('min')) {
          cast_time = `minute`
        }
        var query = `
        WITH cte1 AS (
          SELECT date_diff('${cast_time}', bin(time, ${time_bucket}), bin(${date_end} , ${time_bucket})) AS "time", ${select} FROM "${database_name}"."${table_name}" 
          WHERE 
            time BETWEEN ${date_start} 
            AND ${date_end} 
            AND ${camera_selection}
            
            AND if('${frame_area}' = 'frame_area', TRUE, frame_area >= ${frame_area})
          GROUP BY date_diff('${cast_time}', bin(time, ${time_bucket}), bin(${date_end} , ${time_bucket}))${camera_group_by}
          ORDER BY ${order_by}
        )
        SELECT "time"/${increments}, ${select_final} FROM cte1
        `;

        var lambda_params = {
          FunctionName: process.env.VUE_APP_TIMESTREAM_LAMBDA /* required */,
          InvocationType: "RequestResponse",

          Payload: JSON.stringify({
            auth_token: state.jwt_token,
            owner_claim: state.customer_id,
            timestream_query: query,
            timestream_next_token: null

          }),
        };

        var command = new InvokeCommand(lambda_params);

        state.lambda_client.send(command).then((data) => {
          let return_data = binArrayToJson(data.Payload).timestream_response ? binArrayToJson(data.Payload).timestream_response.response : []
          return_data.shift()

          commit("SET_ANALYTICS_PREDATORS", return_data);
          resolve()
        }).catch(() => {
          commit("SET_ANALYTICS_PREDATORS", []);
          reject()
        })

      })
      // return sem_status.images
    },
    DDB_GET_CONTRACTIONS({ commit, state }, params) {
      return new Promise((resolve, reject) => {

        var date_start = `timestamp '${params.date_start}'`
        var date_end = `timestamp '${params.date_end}'`

        var time_bucket = params.bucket

        //var sgie_contraction = "sgie_contraction"
        var frame_area = "frame_area"
        var group_by_cams = params.group_by_cams
        var increments = params.increment_size

        var table_name = params.user_id;
        var database_name = process.env.VUE_APP_TIMESTREAM_DB;

        let select = `count_if(contraction = 'Y') AS "YES"`
        let camera_group_by = ``
        let select_final = `"YES"`
        let cast_time = `hour`
        let order_by = `time DESC`

        let camera_selection = `TRUE`
        if (params.camera_id && params.camera_id.length) {
          let cameras = params.camera_id.join(' OR ').replaceAll(' O', "' O").replaceAll('R ', "R camera_id = '")
          camera_selection = `(camera_id = '${cameras}')`
        }
        if (group_by_cams) {
          select = `count_if(contraction = 'Y') AS "YES", camera_id AS "camera"`
          camera_group_by = `, camera_id`
          select_final = `"YES", "camera"`
          order_by = `time DESC, camera`
        }
        if (time_bucket.includes('day')) {
          cast_time = `day`
        } else if (time_bucket.includes('min')) {
          cast_time = `minute`
        }
        var query = `
        WITH cte1 AS (
          SELECT date_diff('${cast_time}', bin(time, ${time_bucket}), bin(${date_end} , ${time_bucket})) AS "time", ${select} FROM "${database_name}"."${table_name}" 
          WHERE 
            time BETWEEN ${date_start} 
            AND ${date_end} 
            AND ${camera_selection}
            
            AND if('${frame_area}' = 'frame_area', TRUE, frame_area >= ${frame_area})
          GROUP BY date_diff('${cast_time}', bin(time, ${time_bucket}), bin(${date_end} , ${time_bucket}))${camera_group_by}
          ORDER BY ${order_by}
        )
        SELECT "time"/${increments}, ${select_final} FROM cte1
        `;

        var lambda_params = {
          FunctionName: process.env.VUE_APP_TIMESTREAM_LAMBDA /* required */,
          InvocationType: "RequestResponse",

          Payload: JSON.stringify({
            auth_token: state.jwt_token,
            owner_claim: state.customer_id,
            timestream_query: query,
            timestream_next_token: null

          }),
        };

        var command = new InvokeCommand(lambda_params);

        state.lambda_client.send(command).then((data) => {
          let return_data = binArrayToJson(data.Payload).timestream_response ? binArrayToJson(data.Payload).timestream_response.response : []
          return_data.shift()

          commit("SET_ANALYTICS_CONTRACTIONS", return_data);
          resolve()
        }).catch(() => {
          commit("SET_ANALYTICS_CONTRACTIONS", []);
          reject()
        })


      })
      // return sem_status.images
    },
    DDB_GET_ANIMAL_IMAGE_COUNT({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        // process filters
        // date range
        var date_start = `timestamp '${params.date_start}'`
        var date_end = `timestamp '${params.date_end}'`
        var time_bucket = params.bucket
        // var source = "image"
        // var frame_area = "frame_area"
        var group_by_cams = params.group_by_cams
        var increments = params.increment_size

        var table_name = params.user_id;
        var database_name = process.env.VUE_APP_TIMESTREAM_DB;

        let select = `count(*) AS "animal count"`
        let camera_group_by = ``
        let select_final = `"animal count"`
        let cast_time = `hour`
        let order_by = `time DESC`

        let camera_selection = `TRUE`
        if (params.camera_id && params.camera_id.length) {
          let cameras = params.camera_id.join(' OR ').replaceAll(' O', "' O").replaceAll('R ', "R camera_id = '")
          camera_selection = `(camera_id = '${cameras}')`
        }
        if (group_by_cams) {
          select = `count(*) AS "animal count", camera_id AS "camera"`
          camera_group_by = `, camera_id`
          select_final = `"animal count", "camera"`
          order_by = `time DESC, camera`
        }
        if (time_bucket.includes('day')) {
          cast_time = `day`
        } else if (time_bucket.includes('min')) {
          cast_time = `minute`
        }
        var query = `
        WITH cte1 AS (
          SELECT date_diff('${cast_time}', bin(time, ${time_bucket}), bin(${date_end}, ${time_bucket})) AS "time", ${select} FROM "${database_name}"."${table_name}" 
          WHERE 
          time BETWEEN ${date_start} 
            AND ${date_end} 
            AND ${camera_selection}
            AND source = 'image' 
          GROUP BY date_diff('${cast_time}', bin(time, ${time_bucket}), bin(${date_end}, ${time_bucket}))${camera_group_by}
          ORDER BY ${order_by}
        )
        SELECT "time"/${increments}, ${select_final} FROM cte1
        `;

        var lambda_params = {
          FunctionName: process.env.VUE_APP_TIMESTREAM_LAMBDA /* required */,
          InvocationType: "RequestResponse",

          Payload: JSON.stringify({
            auth_token: state.jwt_token,
            owner_claim: state.customer_id,
            timestream_query: query,
            timestream_next_token: null

          }),
        };
        var command = new InvokeCommand(lambda_params);

        state.lambda_client.send(command).then((data) => {
          console.log(data)

          let return_data = binArrayToJson(data.Payload).timestream_response ? binArrayToJson(data.Payload).timestream_response.response : []
          return_data.shift()

          commit("SET_ANALYTICS_ANIMAL_IMAGE_COUNT", return_data);
          resolve()
        }).catch((err) => {
          console.log(err)
          commit("SET_ANALYTICS_ANIMAL_IMAGE_COUNT", []);
          reject()
        })

      })
      // return sem_status.images
    },

    DDB_GET_ANIMAL_IMAGE_BY_PGIE_COUNT({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        // process filters
        // date range
        var date_start = `timestamp '${params.date_start}'`
        var date_end = `timestamp '${params.date_end}'`
        var time_bucket = params.bucket
        // var source = "image"
        // var frame_area = "frame_area"
        var increments = params.increment_size

        var table_name = params.user_id;
        var database_name = process.env.VUE_APP_TIMESTREAM_DB;

        let cast_time = `hour`

        let camera_selection = `TRUE`
        if (params.camera_id && params.camera_id.length) {
          let cameras = params.camera_id.join(' OR ').replaceAll(' O', "' O").replaceAll('R ', "R camera_id = '")
          camera_selection = `(camera_id = '${cameras}')`
        }

        if (time_bucket.includes('day')) {
          cast_time = `day`
        } else if (time_bucket.includes('min')) {
          cast_time = `minute`
        }
        var query = `
        WITH cte1 AS (
          SELECT date_diff('${cast_time}', bin(time, ${time_bucket}), bin(${date_end}, ${time_bucket})) AS "time", count(*) AS "animal_count", species AS "species" FROM "${database_name}"."${table_name}" 
          WHERE 
          time BETWEEN ${date_start} 
            AND ${date_end}
            AND source = 'image' 
            AND ${camera_selection}
          GROUP BY date_diff('${cast_time}', bin(time, ${time_bucket}), bin(${date_end}, ${time_bucket})), species
          ORDER BY time DESC, species
        )
        SELECT "time"/${increments}, "animal_count", "species" FROM cte1
        `;

        var lambda_params = {
          FunctionName: process.env.VUE_APP_TIMESTREAM_LAMBDA /* required */,
          InvocationType: "RequestResponse",

          Payload: JSON.stringify({
            auth_token: state.jwt_token,
            owner_claim: state.customer_id,
            timestream_query: query,
            timestream_next_token: null

          }),
        };
        var command = new InvokeCommand(lambda_params);

        state.lambda_client.send(command).then((data) => {
          let return_data = binArrayToJson(data.Payload).timestream_response ? binArrayToJson(data.Payload).timestream_response.response : []
          return_data.shift()

          commit("SET_ANALYTICS_ANIMAL_IMAGE_BY_PGIE_COUNT", return_data);
          resolve()
        }).catch(() => {
          commit("SET_ANALYTICS_ANIMAL_IMAGE_BY_PGIE_COUNT", []);
          reject()
        })

      })
      // return sem_status.images
    },
    DDB_GET_ANIMAL_BY_PGIE_COUNT({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        // process filters
        // date range
        var date_start = `timestamp '${params.date_start}'`
        var date_end = `timestamp '${params.date_end}'`
        var time_bucket = params.bucket
        // var source = "image"
        // var frame_area = "frame_area"
        var increments = params.increment_size

        var table_name = params.user_id;
        var database_name = process.env.VUE_APP_TIMESTREAM_DB;

        let cast_time = `hour`
        let camera_selection = `TRUE`
        if (params.camera_id && params.camera_id.length) {
          let cameras = params.camera_id.join(' OR ').replaceAll(' O', "' O").replaceAll('R ', "R camera_id = '")
          camera_selection = `(camera_id = '${cameras}')`
        }
        if (time_bucket.includes('day')) {
          cast_time = `day`
        } else if (time_bucket.includes('min')) {
          cast_time = `minute`
        }
        var query = `
        WITH cte1 AS (
          SELECT date_diff('${cast_time}', bin(time, ${time_bucket}), bin(${date_end}, ${time_bucket})) AS "time", count(DISTINCT id_animal) AS "animal_count", species AS "species" FROM "${database_name}"."${table_name}" 
          WHERE 
          time BETWEEN ${date_start} 
            AND ${date_end}
            AND ${camera_selection}
          GROUP BY date_diff('${cast_time}', bin(time, ${time_bucket}), bin(${date_end}, ${time_bucket})), species
          ORDER BY time DESC, species
        )
        SELECT "time"/${increments}, "animal_count", "species" FROM cte1
        `;

        var lambda_params = {
          FunctionName: process.env.VUE_APP_TIMESTREAM_LAMBDA /* required */,
          InvocationType: "RequestResponse",

          Payload: JSON.stringify({
            auth_token: state.jwt_token,
            owner_claim: state.customer_id,
            timestream_query: query,
            timestream_next_token: null

          }),
        };
        var command = new InvokeCommand(lambda_params);

        state.lambda_client.send(command).then((data) => {
          let return_data = binArrayToJson(data.Payload).timestream_response ? binArrayToJson(data.Payload).timestream_response.response : []
          return_data.shift()

          commit("SET_ANALYTICS_ANIMALS_BY_PGIE_COUNT", return_data);
          resolve()
        }).catch(() => {
          commit("SET_ANALYTICS_ANIMALS_BY_PGIE_COUNT", []);
          reject()
        })

      })
    },
    DDB_GET_CONTRACTIONS_BY_PGIE_COUNT({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        // process filters
        // date range
        var date_start = `timestamp '${params.date_start}'`
        var date_end = `timestamp '${params.date_end}'`
        var time_bucket = params.bucket
        // var source = "image"
        // var frame_area = "frame_area"
        var increments = params.increment_size

        var table_name = params.user_id;
        var database_name = process.env.VUE_APP_TIMESTREAM_DB;

        let cast_time = `hour`
        let camera_selection = `TRUE`
        if (params.camera_id && params.camera_id.length) {
          let cameras = params.camera_id.join(' OR ').replaceAll(' O', "' O").replaceAll('R ', "R camera_id = '")
          camera_selection = `(camera_id = '${cameras}')`
        }
        if (time_bucket.includes('day')) {
          cast_time = `day`
        } else if (time_bucket.includes('min')) {
          cast_time = `minute`
        }
        var query = `
        WITH cte1 AS (
          SELECT date_diff('${cast_time}', bin(time, ${time_bucket}), bin(${date_end}, ${time_bucket})) AS "time", count_if(contraction = 'Y') AS "animal_count", species AS "species" FROM "${database_name}"."${table_name}" 
          WHERE 
          time BETWEEN ${date_start} 
            AND ${date_end}
            AND ${camera_selection}
          GROUP BY date_diff('${cast_time}', bin(time, ${time_bucket}), bin(${date_end}, ${time_bucket})), species
          ORDER BY time DESC, species
        )
        SELECT "time"/${increments}, "animal_count", "species" FROM cte1
        `;

        var lambda_params = {
          FunctionName: process.env.VUE_APP_TIMESTREAM_LAMBDA /* required */,
          InvocationType: "RequestResponse",

          Payload: JSON.stringify({
            auth_token: state.jwt_token,
            owner_claim: state.customer_id,
            timestream_query: query,
            timestream_next_token: null

          }),
        };
        var command = new InvokeCommand(lambda_params);

        state.lambda_client.send(command).then((data) => {
          let return_data = binArrayToJson(data.Payload).timestream_response ? binArrayToJson(data.Payload).timestream_response.response : []
          return_data.shift()

          commit("SET_ANALYTICS_CONTRACTIONS_BY_PGIE_COUNT", return_data);
          resolve()
        }).catch(() => {
          commit("SET_ANALYTICS_CONTRACTIONS_BY_PGIE_COUNT", []);
          reject()
        })

      })
    },
    DDB_GET_PREDATORS_BY_PGIE_COUNT({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        // process filters
        // date range
        var date_start = `timestamp '${params.date_start}'`
        var date_end = `timestamp '${params.date_end}'`
        var time_bucket = params.bucket
        // var source = "image"
        // var frame_area = "frame_area"
        var increments = params.increment_size

        var table_name = params.user_id;
        var database_name = process.env.VUE_APP_TIMESTREAM_DB;

        let cast_time = `hour`
        let camera_selection = `TRUE`
        if (params.camera_id && params.camera_id.length) {
          let cameras = params.camera_id.join(' OR ').replaceAll(' O', "' O").replaceAll('R ', "R camera_id = '")
          camera_selection = `(camera_id = '${cameras}')`
        }

        if (time_bucket.includes('day')) {
          cast_time = `day`
        } else if (time_bucket.includes('min')) {
          cast_time = `minute`
        }
        var query = `
        WITH cte1 AS (
          SELECT date_diff('${cast_time}', bin(time, ${time_bucket}), bin(${date_end}, ${time_bucket})) AS "time", count_if(predator = 'Y') AS "animal_count", species AS "species" FROM "${database_name}"."${table_name}" 
          WHERE 
          time BETWEEN ${date_start} 
            AND ${date_end}
            AND ${camera_selection}
          GROUP BY date_diff('${cast_time}', bin(time, ${time_bucket}), bin(${date_end}, ${time_bucket})), species
          ORDER BY time DESC, species
        )
        SELECT "time"/${increments}, "animal_count", "species" FROM cte1
        `;

        var lambda_params = {
          FunctionName: process.env.VUE_APP_TIMESTREAM_LAMBDA /* required */,
          InvocationType: "RequestResponse",

          Payload: JSON.stringify({
            auth_token: state.jwt_token,
            owner_claim: state.customer_id,
            timestream_query: query,
            timestream_next_token: null

          }),
        };

        var command = new InvokeCommand(lambda_params);

        state.lambda_client.send(command).then((data) => {
          let return_data = binArrayToJson(data.Payload).timestream_response ? binArrayToJson(data.Payload).timestream_response.response : []
          return_data.shift()

          commit("SET_ANALYTICS_PREDATORS_BY_PGIE_COUNT", return_data);
          resolve()
        }).catch(() => {
          commit("SET_ANALYTICS_PREDATORS_BY_PGIE_COUNT", []);
          reject()
        })

      })
    },
    DDB_GET_ANIMAL_COUNT({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        // process filters
        // date range
        var date_start = `timestamp '${params.date_start}'`
        var date_end = `timestamp '${params.date_end}'`

        var time_bucket = params.bucket

        var group_by_cams = params.group_by_cams
        var increments = params.increment_size

        //   if(params.date_range){
        //       date_start = `timestamp '${moment(params.date_range, 'YYYY-MM-DDTHH').format("YYYY-MM-DD HH:mm:ss")}'`
        //       date_end = `timestamp '${moment(params.date_range, 'YYYY-MM-DDTHH').add(25, 'hours').format("YYYY-MM-DD HH:mm:ss")}'`
        // }


        // user id
        var table_name = params.user_id;
        var database_name = process.env.VUE_APP_TIMESTREAM_DB;

        let select = `count(DISTINCT id_animal) AS "animal count"`
        let camera_group_by = ``
        let select_final = `"animal count"`
        let cast_time = `hour`
        let order_by = `time DESC`

        let camera_selection = `TRUE`
        if (params.camera_id && params.camera_id.length) {
          let cameras = params.camera_id.join(' OR ').replaceAll(' O', "' O").replaceAll('R ', "R camera_id = '")
          camera_selection = `(camera_id = '${cameras}')`
        }
        if (group_by_cams) {
          select = `count(DISTINCT id_animal) AS "animal count", camera_id AS "camera"`
          camera_group_by = `, camera_id`
          select_final = `"animal count", "camera"`
          order_by = `time DESC, camera`
        }
        if (time_bucket.includes('day')) {
          cast_time = `day`
        } else if (time_bucket.includes('min')) {
          cast_time = `minute`
        }
        var query = `
        WITH cte1 AS (
          SELECT date_diff('${cast_time}', bin(time, ${time_bucket}), bin(${date_end} , ${time_bucket})) AS "time", ${select} FROM "${database_name}"."${table_name}" 
          WHERE 
            time BETWEEN ${date_start} 
            AND ${date_end} 
            AND ${camera_selection}
          GROUP BY date_diff('${cast_time}', bin(time, ${time_bucket}), bin(${date_end} , ${time_bucket}))${camera_group_by}
          ORDER BY ${order_by}
        )
        SELECT "time"/${increments}, ${select_final} FROM cte1
        `;
        var lambda_params = {
          FunctionName: process.env.VUE_APP_TIMESTREAM_LAMBDA /* required */,
          InvocationType: "RequestResponse",

          Payload: JSON.stringify({
            auth_token: state.jwt_token,
            owner_claim: state.customer_id,
            timestream_query: query,
            timestream_next_token: null
          }),
        };

        var command = new InvokeCommand(lambda_params);

        state.lambda_client.send(command).then((data) => {
          let return_data = binArrayToJson(data.Payload).timestream_response ? binArrayToJson(data.Payload).timestream_response.response : []
          return_data.shift()

          commit("SET_ANALYTICS_ANIMAL_COUNT", return_data);
          resolve()
        }).catch(() => {
          commit("SET_ANALYTICS_ANIMAL_COUNT", []);
          reject()
        })


      })
      // return sem_status.images
    },


    DDB_GET_IMAGES({ commit, state }, params) {
      var request_number = 1;
      if (sem_status.image_number) {
        request_number = sem_status.image_number + 1;
      }
      sem_status.image_number = request_number;
      return new Promise((resolve, reject) => {
        // process filters
        // date range
        var date_start = "ago(24h)"
        var date_end = "now()"
        if (params.date_range) {
          date_start = `timestamp '${moment(params.date_range[0], 'YYYY-MM-DDTHH:mm').format("YYYY-MM-DD HH:mm:ss")}'`
          date_end = params.date_range[1] == "Invalid date" ? `timestamp '${moment(params.date_range[0], 'YYYY-MM-DDTHH:mm').add(25, 'hours').format("YYYY-MM-DD HH:mm:ss")}'` : `timestamp '${moment(params.date_range[1], 'YYYY-MM-DDTHH:mm').format("YYYY-MM-DD HH:mm:ss")}'`
        }
        // image filters
        var image_condition = [];
        if (params.image_filters) {
          // PGIE condition
          var PGIE_types = [];
          for (let key in params.image_filters.PGIE) {
            if (params.image_filters.PGIE[key]) {
              PGIE_types.push(`species = '${key}'`);
            }
          }
          if (PGIE_types.length > 0) {
            PGIE_types = `(${PGIE_types.join(" OR ")})`;
            image_condition.push(PGIE_types);
          }

          // SGIE condition
          var SGIE_types = [];
          for (let key in params.image_filters.SGIE) {
            if (params.image_filters.SGIE[key]) {
              if (key == "knee" || key == "hoof") {
                for (let i = 1; i <= 4; i++) {
                  SGIE_types.push(`bbox_${key}_${i} IS NOT NULL`);
                }
              } else {
                SGIE_types.push(`bbox_${key} IS NOT NULL`);
              }
            }
          }
          if (SGIE_types.length > 0) {
            SGIE_types = `(${SGIE_types.join(" OR ")})`;
            image_condition.push(SGIE_types);
          }

          // Contraction condition
          var Contraction_types = [];
          // for (let key in params.image_filters.Contraction) {
          if (params.image_filters.Contraction) {
            Contraction_types.push(`contraction = 'Y'`);
          }
          if (params.image_filters.Predators) {
            Contraction_types.push(`predator = 'Y'`);
          }
          // }
          if (Contraction_types.length > 0) {
            Contraction_types = `(${Contraction_types.join(" OR ")})`;
            image_condition.push(Contraction_types);
          }

          // Contraction condition
          var Suckling_types = [];
          for (let key in params.image_filters.Suckling) {
            if (params.image_filters.Suckling[key]) {
              Suckling_types.push(`suckling = '${key}'`);
            }
          }
          if (Suckling_types.length > 0) {
            Suckling_types = `(${Suckling_types.join(" OR ")})`;
            image_condition.push(Suckling_types);
          }

          // Activity condition
          var Activity_types = [];
          for (let key in params.image_filters.Activity) {
            if (params.image_filters.Activity[key]) {
              Activity_types.push(`activity = '${key}'`);
            }
          }
          if (Activity_types.length > 0) {
            Activity_types = `(${Activity_types.join(" OR ")})`;
            image_condition.push(Activity_types);
          }

          // Age condition
          var Age_types = [];
          for (let key in params.image_filters.Age) {
            if (params.image_filters.Age[key]) {
              Age_types.push(`age = '${key}'`);
            }
          }
          if (Age_types.length > 0) {
            Age_types = `(${Age_types.join(" OR ")})`;
            image_condition.push(Age_types);
          }
        }

        var cameras = [];
        if (params.camera_id) {
          for (let i in params.camera_id) {
            if (params.camera_id[i]) {
              cameras.push(`camera_id = '${params.camera_id[i]}'`);
            }
          }
          if (cameras.length > 0) {
            cameras = `(${cameras.join(" OR ")})`;
            image_condition.push(cameras);
          }
        }

        // if (params.camera_id) {
        //   image_condition.push(`(camera_id = '${params.camera_id}')`)
        // }
        if (params.image_filters.Tags) {
          image_condition.push(`(id_animal IS NOT NULL)`);
        }

        if (image_condition.length > 0) {
          image_condition = "AND " + image_condition.join(" AND ");
        } else {
          image_condition = "";
        }
        // model versions
        if (params.model_versions) {
          for (let key in params.model_versions) {
            if (params.model_versions[key] || params.model_versions[key] === 0) {
              image_condition = image_condition + ` AND ${key} = '${params.model_versions[key]}'`
            }
          }
        }

        // user id
        var table_name = params.user_id;
        var database_name = process.env.VUE_APP_TIMESTREAM_DB;
        // array_agg(bbox_hoof_1) AS "SGIE_hoof_list_1",
        // array_agg(bbox_hoof_2) AS "SGIE_hoof_list_2",
        // array_agg(bbox_hoof_3) AS "SGIE_hoof_list_3",
        // array_agg(bbox_hoof_4) AS "SGIE_hoof_list_4",
        // array_agg(bbox_knee_1) AS "SGIE_knee_list_1",
        // array_agg(bbox_knee_2) AS "SGIE_knee_list_2",
        // array_agg(bbox_knee_3) AS "SGIE_knee_list_3",
        // array_agg(bbox_knee_4) AS "SGIE_knee_list_4"

        // "SGIE_hoof_list_1",
        // "SGIE_hoof_list_2",
        // "SGIE_hoof_list_3",
        // "SGIE_hoof_list_4",
        // "SGIE_knee_list_1",
        // "SGIE_knee_list_2",
        // "SGIE_knee_list_3",
        // "SGIE_knee_list_4"
        var query = `
        WITH cte1 AS (
          SELECT user_id, camera_id, time FROM "${database_name}"."${table_name}" 
          WHERE 
            source = 'image' 
            AND time BETWEEN ${date_start} AND ${date_end} ${image_condition}
          ORDER BY time ${params.sort}, camera_id DESC
        ), cte2 AS (
          SELECT 
            user_id, 
            camera_id, 
            time, 
            array_agg(bbox_body) AS "PGIE_bbox_list", 
            array_agg(species) AS "PGIE_species_list",
            array_agg(bbox_head) AS "SGIE_head_list",
            
            array_agg(bbox_tail) AS "SGIE_tail_list"
          FROM "${database_name}"."${table_name}" 
          WHERE 
            source = 'image' 
            AND time BETWEEN ${date_start} AND ${date_end} ${image_condition}
          GROUP BY user_id, camera_id, time
          ORDER BY time ${params.sort}, camera_id DESC
        )
        SELECT cte1.user_id, cte1.camera_id, cte1.time, 
          "PGIE_bbox_list", 
          "PGIE_species_list",
          "SGIE_head_list",
          "SGIE_tail_list"
        FROM cte1
        LEFT JOIN cte2 
          ON cte1.user_id = cte2.user_id
            AND cte1.camera_id = cte2.camera_id
            AND cte1.time = cte2.time
        ORDER BY time ${params.sort}, camera_id DESC
        `;
        // SELECT DISTINCT user_id, camera_id, time FROM "${database_name}"."${table_name}" 
        // WHERE 
        //   source = 'image' 
        //   AND time BETWEEN ${date_start} AND ${date_end} ${image_filter} ${sgie_contraction}
        // ORDER BY time DESC
        var query_params = {
          QueryString: query,
          MaxRows: params.limit,
          NextToken: null
        }
        if (state.images[1] && !params.first_time) {
          query_params.NextToken = state.images[1];
        }



        var lambda_params = {
          FunctionName: process.env.VUE_APP_TIMESTREAM_LAMBDA /* required */,
          InvocationType: "RequestResponse",

          Payload: JSON.stringify({
            auth_token: state.jwt_token,
            owner_claim: state.customer_id,
            timestream_query: query,
            timestream_next_token: query_params.NextToken,
            max_rows: 64,
          }),
        };


        var command = new InvokeCommand(lambda_params);
        console.log(state.lambda_client)
        state.lambda_client.send(command).then((data) => {
          var image_data = binArrayToJson(data.Payload).timestream_response
          if (!image_data) {
            commit("SET_IMAGES", [[], false]);

            resolve(false)
            return false
          }
          if (image_data.response.length <= 1 && image_data.next_token) {
            commit("SET_IMAGES", [[], image_data.next_token]);

            resolve(image_data.next_token + "run_again")
            return false
          }
          let temp_list = image_data.response
          temp_list.shift()

          var image_list = temp_list.map((e) => {
            var user_id = e.Data[0].ScalarValue;
            var camera_id = e.Data[1].ScalarValue;
            var image_time = moment(e.Data[2].ScalarValue);
            var bbox_list = [];

            // pgie
            var pgie_bbox_list = [];
            if (!e.Data[3].NullValue) {
              pgie_bbox_list = e.Data[3].ArrayValue.map(a => {
                if (a.ScalarValue) {
                  return {
                    ...JSON.parse(a.ScalarValue.replaceAll("'", "\"")),
                    type: ""
                  };
                } else {
                  return null;
                }
              })
            }

            if (!e.Data[4].NullValue) {
              e.Data[4].ArrayValue.map(a => {
                return a.ScalarValue;
              }).forEach((b, index) => {
                if (pgie_bbox_list[index] && b) {
                  pgie_bbox_list[index].type = b
                }
              });
            }

            bbox_list = [...bbox_list, ...pgie_bbox_list.filter(a => !!a)];

            // head
            if (e.Data[5] && !e.Data[5].NullValue && params.image_filters.SGIE.head) {
              bbox_list = [
                ...bbox_list,
                ...e.Data[5].ArrayValue.map(a => {
                  if (a.ScalarValue) {
                    return {
                      ...JSON.parse(a.ScalarValue.replaceAll("'", "\"")),
                      type: "head",
                      label: "head"
                    };
                  } else {
                    return null;
                  }
                }).filter(b => !!b)
              ];
            }


            // tag
            if (e.Data[6] && !e.Data[6].NullValue && params.image_filters.SGIE.tag) {
              bbox_list = [
                ...bbox_list,
                ...e.Data[6].ArrayValue.map(a => {
                  if (a.ScalarValue) {
                    return {
                      ...JSON.parse(a.ScalarValue.replaceAll("'", "\"")),
                      type: "tag",
                      label: "tag"
                    };
                  } else {
                    return null;
                  }
                }).filter(b => !!b)
              ];
            }

            // tail
            if (e.Data[7] && !e.Data[7].NullValue && params.image_filters.SGIE.tail) {
              bbox_list = [
                ...bbox_list,
                ...e.Data[7].ArrayValue.map(a => {
                  if (a.ScalarValue) {
                    return {
                      ...JSON.parse(a.ScalarValue.replaceAll("'", "\"")),
                      type: "tail",
                      label: "tail"
                    };
                  } else {
                    return null;
                  }
                }).filter(b => !!b)
              ];
            }

            var year = String(image_time.year()).padStart(4, "0");
            var month = String(image_time.month() + 1).padStart(2, "0");
            var day = String(image_time.date()).padStart(2, "0");
            var full_date = image_time.format("YYYY-MM-DD_HH-mm-ss")

            return {
              user_id: user_id,
              camera_id: camera_id,
              image_time: image_time.format("YYYY-MM-DD HH:mm:ss"),
              image_path: `${user_id}/${camera_id}/${year}/${month}/${day}/${camera_id}_${full_date}`,
              bbox_list: bbox_list
            }
          })

          if (state.images[0] && !params.first_time) {
            image_list = [state.images[0].concat(image_list)];
          } else {
            image_list = [image_list];
          }
          if (image_data.next_token) {
            image_list.push(image_data.next_token);
          }
          if (request_number >= sem_status.image_number) {
            commit("SET_IMAGES", image_list);
            resolve(image_data.next_token);
          } else {
            reject();
          }

        }).catch(() => {
          if (request_number >= sem_status.image_number) {
            commit("SET_IMAGES", []);
            resolve(null);
          } else {
            reject();
          }
        })

      })
      // return sem_status.images
    },
    DDB_VIDEOS_AT_DATE({ commit }) {
      return new Promise((resolve) => {
        async function get_videos_at_date() {
          try {
            let videos_at_dates_max = await API.graphql({
              query: queries.listVideosByCustomer,
              variables: { customer_id: store.state.customer_id, limit: 1, sortDirection: "DESC" },
              authToken: store.state.lambda_auth_token
            })


            commit('SET_VIDEOS_AT_DATES', moment.tz(videos_at_dates_max.data.listVideosByCustomer.items[0].video_datetime, 'UTC'))
            resolve([moment.tz(videos_at_dates_max.data.listVideosByCustomer.items[0].video_datetime, 'UTC').format("YYYY-MM-DD"), moment.tz(videos_at_dates_max.data.listVideosByCustomer.items[0].video_datetime, 'UTC')])
          } catch (error) {
            //  commit('SET_VIDEOS_AT_DATES', {})

            resolve([moment().format("YYYY-MM-DD"), false])
            //resolve(false)
          }


          //           var dates_array = []

          //           for (const i in videos_at_dates) {
          //             dates_array.push(moment(parseInt(videos_at_dates[i].key)).format("YYYY-MM-DD"))
          //           }

          // commit('SET_VIDEOS_AT_DATES', videos_at_dates)
          // resolve(moment(videos_at_dates).format("YYYY-MM-DD"))

        }
        get_videos_at_date()

      })
    },
    DDB_GET_CUSTOMER_ID({ commit, dispatch }, id) {
      return new Promise((resolve) => {
        async function set_customer_id() {

          commit('SET_CUSTOMER_ID', id)

          const user = await Auth.currentAuthenticatedUser()

          const token = user.getSignInUserSession().getIdToken().jwtToken;
          const lambda_auth_token = 'Bearer ' + btoa(id) + '.' + token;

          var config = {
            method: 'GET',
            withCredentials: true,
            url: process.env.VUE_APP_COOKIE_URL,
            headers: {
              Auth: `Bearer ${token}`,
              Owner: id
            },
          };
          var customer = {}

          axios(config)
            .then(function (response) {
              console.log(response);

            })
            .catch(function (error) {
              console.log(error);
            });

          if (store.state.user.username == id) {

            commit('SET_ALLOWED_OPERATIONS', 'All')
          } else {
            customer = (await API.graphql({
              query: queries.getCustomer,
              variables: {
                id: store.state.user.username
              },
              authToken: store.state.lambda_auth_token_home
            })).data.getCustomer
            let collaborator_companies = []

            if (customer) {

              for (const i in customer.customer_is_collaborator) {

                const token1 = user.getSignInUserSession().getIdToken().jwtToken;
                const lambda_auth_token_1 = 'Bearer ' + btoa(customer.customer_is_collaborator[i].id) + '.' + token1;
                var company = '';

                try {
                  company = (await API.graphql({
                    query: queries.getCollabCustomerCompanyName,
                    variables: {
                      id: customer.customer_is_collaborator[i].id
                    },
                    authToken: lambda_auth_token_1
                  })).data.getCustomer.customer_company_name
                } catch {
                  company = customer.customer_is_collaborator[i].email;
                }
                collaborator_companies.push(company)
              }

              commit('SET_USER_DATA', { customer: customer, collaborator_companies: collaborator_companies })
            } else {
              commit('SET_LOGIN_ERROR', "Something went wrong with your account, please contact support")
            }
          }

          for (const i in customer.customer_is_collaborator) {
            if (customer.customer_is_collaborator[i].id == id) {
              commit('SET_ALLOWED_OPERATIONS', customer.customer_is_collaborator[i].allowed_operations)
            }
          }
          commit('SET_LAMBDA_AUTH_TOKEN', lambda_auth_token)

          if (customer && !(customer.customer_type == 'USER' && store.state.user.username == id)) {

            dispatch('DDB_GET_CUSTOMER_DATA')
          }
          // router.push({ path: '/' })

          resolve()
        }
        set_customer_id()
      })
    },

    DDB_GET_CALENDAR_EVENTS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_calendar_events() {
          var events = []
          var calendar_ready_events = []
          if (store.state.allowed_operations.includes('EventRead') || store.state.allowed_operations == 'All') {
            if (params.event_type != 'ALL' && params.event_search.length > 0) {
              events = (await API.graphql({
                query: queries.listEventsByCustomer,
                variables: { customer_id: store.state.customer_id, limit: 1500, filter: { and: { event_title: { contains: params.event_search }, event_type: { eq: params.event_type } } } },
                authToken: store.state.lambda_auth_token
              })).data.listEventsByCustomer.items
            } else if (params.event_type != 'ALL' && params.event_search.length == 0) {
              events = (await API.graphql({
                query: queries.listEventsByCustomer,
                variables: { customer_id: store.state.customer_id, limit: 1500, filter: { event_type: { eq: params.event_type } } },
                authToken: store.state.lambda_auth_token
              })).data.listEventsByCustomer.items
            } else if (params.event_type == 'ALL' && params.event_search.length > 0) {
              events = (await API.graphql({
                query: queries.listEventsByCustomer,
                variables: { customer_id: store.state.customer_id, limit: 1500, filter: { event_title: { contains: params.event_search } } },
                authToken: store.state.lambda_auth_token
              })).data.listEventsByCustomer.items
            } else {
              events = (await API.graphql({
                query: queries.listEventsByCustomer,
                variables: { customer_id: store.state.customer_id, limit: 1500 },
                authToken: store.state.lambda_auth_token
              })).data.listEventsByCustomer.items
            }
            if (params.association_filter != 'ALL' && params.association == 'Group') {
              events = events.filter(p => p.group_id == params.association_filter)
            } else if (params.association_filter != 'ALL' && params.association == 'Herd') {
              events = events.filter(p => p.herd_id == params.association_filter)
            } else if (params.association_filter != 'ALL' && params.association == 'Zone') {
              events = events.filter(p => p.zone_id == params.association_filter)
            }

            for (const i in events) {
              calendar_ready_events.push({
                id: events[i].id,
                name: events[i].event_title,
                start: moment(events[i].event_datetime_start, "YYYY-MM-DDTHH:mm:ss.sssZ").format("YYYY-MM-DD HH:mm"),
                end: events[i].event_datetime_end ? moment(events[i].event_datetime_end, "YYYY-MM-DDTHH:mm:ss.sssZ").format("YYYY-MM-DD HH:mm") : '',
                color: events[i].event_color,
                timed: events[i].event_datetime_end ? false : true,
              })
            }
          }

          commit('SET_CALENDAR_EVENTS', calendar_ready_events)

          resolve()
        }
        get_calendar_events()
      })
    },

    DDB_BULL_MEASUREMENTS({ commit }) {
      return new Promise((resolve) => {
        async function get_herds() {
          if (store.state.allowed_operations.includes('MeasurementRead') || store.state.allowed_operations == 'All') {
            commit('SET_SCROTUM_CIRCUMFERENCE', (await API.graphql({
              query: queries.listMeasurementsByCustomer,
              variables: { customer_id: store.state.customer_id, limit: 4000, filter: { measurement_type: { eq: 'SCROTUM_CIRCUMFERENCE' } } },
              authToken: store.state.lambda_auth_token
            })).data.listMeasurementsByCustomer.items)
            commit('SET_SPERM_MORPHOLOGY', (await API.graphql({
              query: queries.listMeasurementsByCustomer,
              variables: { customer_id: store.state.customer_id, limit: 4000, filter: { measurement_type: { eq: 'SPERM_MORPHOLOGY' } } },
              authToken: store.state.lambda_auth_token
            })).data.listMeasurementsByCustomer.items)
          }

          resolve()
        }
        get_herds()
      })
    },

    DDB_GET_BULLS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_herds() {
          var animals = []


          if (params && params.herd && params.herd != 'all' && (!params.group || params.group == 'all')) {
            animals = (store.state.allowed_operations.includes('AnimalRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
              query: queries.listAnimalsByHerd,
              variables: { herd_id: params.herd, filter: { animal_classification: { eq: 'BULL' } } },
              authToken: store.state.lambda_auth_token
            })).data.listAnimalsByHerd.items : []

          } else if (params && params.group && params.group != 'all' && (!params.herd || params.herd == 'all')) {
            let temp_animals = (store.state.allowed_operations.includes('GroupRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
              query: queries.getGroup,
              variables: { id: params.group },
              authToken: store.state.lambda_auth_token
            })).data.getGroup.animals.items : []
            for (const i in temp_animals) {
              if (temp_animals[i].animal.animal_classification == 'BULL') {
                animals.push(temp_animals[i].animal)
              }
            }
          } else {
            animals = (store.state.allowed_operations.includes('AnimalRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
              query: queries.listAnimalsByCustomer,
              variables: { customer_id: store.state.customer_id, filter: { animal_classification: { eq: 'BULL' } }, limit: 2000 },
              authToken: store.state.lambda_auth_token
            })).data.listAnimalsByCustomer.items : []
          }
          commit('SET_BULLS', animals)
          resolve()
        }
        get_herds()
      })
    },

    DDB_GET_COWS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_herds() {
          var animals = []

          if (params && params.herd && params.herd != 'all' && (!params.group || params.group == 'all')) {
            animals = (store.state.allowed_operations.includes('AnimalRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
              query: queries.listAnimalsByHerd,
              variables: { herd_id: params.herd, filter: { animal_classification: { eq: 'COW' } } },
              authToken: store.state.lambda_auth_token
            })).data.listAnimalsByHerd.items : []

          } else if (params && params.group && params.group != 'all' && (!params.herd || params.herd == 'all')) {
            let temp_animals = (store.state.allowed_operations.includes('GroupRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
              query: queries.getGroup,
              variables: { id: params.group },
              authToken: store.state.lambda_auth_token
            })).data.getGroup.animals.items : []
            for (const i in temp_animals) {
              if (temp_animals[i].animal.animal_classification == 'COW') {
                animals.push(temp_animals[i].animal)
              }
            }
          } else {
            animals = (store.state.allowed_operations.includes('AnimalRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
              query: queries.listAnimalsByCustomer,
              variables: { customer_id: store.state.customer_id, filter: { animal_classification: { eq: 'COW' } }, limit: 2000 },
              authToken: store.state.lambda_auth_token
            })).data.listAnimalsByCustomer.items : []
          }
          commit('SET_COWS', animals)
          resolve()
        }
        get_herds()
      })
    },

    DDB_GET_CALVES({ commit }, params) {
      return new Promise((resolve) => {
        async function get_herds() {
          var animals = []

          if (params && params.herd && params.herd != 'all' && (!params.group || params.group == 'all')) {
            animals = (store.state.allowed_operations.includes('AnimalRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
              query: queries.listAnimalsByHerd,
              variables: { herd_id: params.herd, filter: { animal_dob: { beginsWith: moment().year().toString() } } },
              authToken: store.state.lambda_auth_token
            })).data.listAnimalsByHerd.items : []

          } else if (params && params.group && params.group != 'all' && (!params.herd || params.herd == 'all')) {
            let temp_animals = (store.state.allowed_operations.includes('GroupRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
              query: queries.getGroup,
              variables: { id: params.group },
              authToken: store.state.lambda_auth_token
            })).data.getGroup.animals.items : []
            for (const i in temp_animals) {
              if (temp_animals[i].animal.animal_dob.slice(0, 4) == moment().year().toString()) {
                animals.push(temp_animals[i].animal)
              }
            }

          } else {
            animals = (store.state.allowed_operations.includes('AnimalRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
              query: queries.listAnimalsByCustomer,
              variables: { customer_id: store.state.customer_id, filter: { animal_dob: { beginsWith: moment().year().toString() } }, limit: 2000 },
              authToken: store.state.lambda_auth_token
            })).data.listAnimalsByCustomer.items : []
          }
          commit('SET_CALVES', animals)
          resolve()
        }
        get_herds()
      })
    },

    DDB_GET_GROUPS({ commit }) {
      return new Promise((resolve) => {
        async function get_herds() {
          let groups = (store.state.allowed_operations.includes('GroupRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            query: queries.listGroupsByCustomer,
            variables: {
              customer_id: store.state.customer_id
            },
            authToken: store.state.lambda_auth_token
          })).data.listGroupsByCustomer.items : []
          var animal_groups = {}
          commit('SET_GROUPS', sort_by_key(groups), 'group_name')
          for (const n in groups) {
            let temp_animal_groups = (store.state.allowed_operations.includes('GroupRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
              query: queries.listAnimalGroupByGroup,
              variables: {
                group_id: groups[n].id
              },
              authToken: store.state.lambda_auth_token
            })).data.listAnimalGroupByGroup.items : []
            for (const i in temp_animal_groups) {
              if (temp_animal_groups[i].animal_id && temp_animal_groups[i].group_id) {
                if (animal_groups[temp_animal_groups[i].animal_id]) {
                  animal_groups[temp_animal_groups[i].animal_id].name.push(temp_animal_groups[i].group.group_name)
                  animal_groups[temp_animal_groups[i].animal_id].id.push(temp_animal_groups[i].group_id)
                  animal_groups[temp_animal_groups[i].animal_id].ag_id.push(temp_animal_groups[i].id)
                } else {
                  animal_groups[temp_animal_groups[i].animal_id] = { name: [temp_animal_groups[i].group.group_name], id: [temp_animal_groups[i].group_id], ag_id: [temp_animal_groups[i].id] }
                }
              }
            }
          }
          commit('SET_ANIMAL_GROUPS', animal_groups)
          resolve()
        }
        get_herds()
      })
    },

    DDB_GET_ZONES({ commit }) {
      return new Promise((resolve) => {
        async function get_herds() {
          let zones = (store.state.allowed_operations.includes('ZoneRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            query: queries.listZonesByCustomer,
            variables: {
              customer_id: store.state.customer_id
            },
            authToken: store.state.lambda_auth_token
          })).data.listZonesByCustomer.items : []

          commit('SET_ZONES', sort_by_key(zones, 'zone_name'))


          resolve()
        }
        get_herds()
      })
    },
    DDB_GET_HERDS({ commit }) {
      return new Promise((resolve) => {
        async function get_herds() {
          let herds = (store.state.allowed_operations.includes('HerdRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            query: queries.listHerdsByCustomer,
            variables: {
              customer_id: store.state.customer_id
            },
            authToken: store.state.lambda_auth_token
          })).data.listHerdsByCustomer.items : []
          commit('SET_HERDS', sort_by_key(herds, 'herd_name'))

          resolve()
        }
        get_herds()
      })
    },
    DDB_GET_NOTES({ commit }) {
      return new Promise((resolve) => {
        async function get_notes_data() {

          let notes = (store.state.allowed_operations.includes('NoteRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            query: queries.listNotesByCustomer,
            variables: {
              customer_id: store.state.customer_id,
              limit: 4000
            },
            authToken: store.state.lambda_auth_token
          })).data.listNotesByCustomer.items : []
          commit('SET_NOTES', notes)

          resolve()
        }
        get_notes_data()
      })
    },
    DDB_GET_LAST_FOOTAGE({ commit }, params) {
      return new Promise((resolve) => {
        async function get_camera(params) {

          // var corruption = false
          // if (params.curruption == "Unknown") {
          //   corruption = "UNDEFINED"
          // } else if (params.curruption == "Corrupted") {
          //   corruption = "YES"
          // } if (params.curruption == "Non Corrupted") {
          //   corruption = "NO"
          // }

          // var source = false
          // if (params.camera_latest_upload_source !== "All Sources") {
          //   source = params.camera_latest_upload_source
          // }

          var resolution = false
          if (params.camera_latest_upload_resolution !== "Resolution") {
            resolution = params.camera_latest_upload_resolution
          }

          let lf = (store.state.allowed_operations.includes('CameraRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            query: queries.listCamerasByCustomer,
            variables: {
              customer_id: store.state.customer_id
            },
            authToken: store.state.lambda_auth_token
          })).data.listCamerasByCustomer.items : []
          if (resolution) {
            lf = lf.filter(p => p.camera_latest_upload_resolution == resolution)
          }
          commit('SET_LAST_FOOTAGE', lf)
          resolve()
        }
        get_camera(params)
      })
    },

    DDB_GET_NOTIFICATIONS({ commit }, params) {
      return new Promise((resolve) => {
        var _this = this
        async function get_events(params) {
          _this.state.event_params = params
          let alerts = []
          let notices = []
          let warnings = []
          if (store.state.allowed_operations.includes('EventRead') || store.state.allowed_operations == 'All') {
            try {
              if (params.search_str || (params.camera && params.camera != 'All') || (params.type && params.type != 'All')) {
                let filter_obj = { notification_level: { eq: 'ALERT' } }
                if (params.search_str) {
                  filter_obj.notification_title = { contains: params.search_str }
                }
                if ((params.camera && params.camera != 'All')) {
                  filter_obj.camera_id = { eq: params.camera }

                }
                if ((params.type && params.type != 'All')) {
                  filter_obj.notification_type = { eq: params.type }
                }
                alerts = (await API.graphql({
                  query: queries.listNotificationsByCustomer,
                  variables: { customer_id: store.state.customer_id, notification_datetime: { between: [params.date, moment(params.date, 'YYYY-MM-DDTHH').add(25, 'hours').format('YYYY-MM-DDTHH')] }, limit: 1500, filter: { and: filter_obj } },
                  authToken: store.state.lambda_auth_token
                })).data.listNotificationsByCustomer.items

                filter_obj.notification_level = { eq: 'INFO' }
                notices = (await API.graphql({
                  query: queries.listNotificationsByCustomer,
                  variables: { customer_id: store.state.customer_id, notification_datetime: { between: [params.date, moment(params.date, 'YYYY-MM-DDTHH').add(25, 'hours').format('YYYY-MM-DDTHH')] }, limit: 1500, filter: { and: filter_obj } },
                  authToken: store.state.lambda_auth_token
                })).data.listNotificationsByCustomer.items

                filter_obj.notification_level = { eq: 'WARNING' }
                warnings = (await API.graphql({
                  query: queries.listNotificationsByCustomer,
                  variables: { customer_id: store.state.customer_id, notification_datetime: { between: [params.date, moment(params.date, 'YYYY-MM-DDTHH').add(25, 'hours').format('YYYY-MM-DDTHH')] }, limit: 1500, filter: { and: filter_obj } },
                  authToken: store.state.lambda_auth_token
                })).data.listNotificationsByCustomer.items
              } else {
                alerts = (await API.graphql({
                  query: queries.listNotificationsByCustomer,
                  variables: { customer_id: store.state.customer_id, notification_datetime: { between: [params.date, moment(params.date, 'YYYY-MM-DDTHH').add(25, 'hours').format('YYYY-MM-DDTHH')] }, limit: 1500, filter: { notification_level: { eq: 'ALERT' } } },
                  authToken: store.state.lambda_auth_token
                })).data.listNotificationsByCustomer.items
                notices = (await API.graphql({
                  query: queries.listNotificationsByCustomer,
                  variables: { customer_id: store.state.customer_id, notification_datetime: { between: [params.date, moment(params.date, 'YYYY-MM-DDTHH').add(25, 'hours').format('YYYY-MM-DDTHH')] }, limit: 1500, filter: { notification_level: { eq: 'INFO' } } },
                  authToken: store.state.lambda_auth_token
                })).data.listNotificationsByCustomer.items
                warnings = (await API.graphql({
                  query: queries.listNotificationsByCustomer,
                  variables: { customer_id: store.state.customer_id, notification_datetime: { between: [params.date, moment(params.date, 'YYYY-MM-DDTHH').add(25, 'hours').format('YYYY-MM-DDTHH')] }, limit: 1500, filter: { notification_level: { eq: 'WARNING' } } },
                  authToken: store.state.lambda_auth_token
                })).data.listNotificationsByCustomer.items
              }

              let [alerts1, notices1, warnings1] = await Promise.allSettled([alerts, notices, warnings]);
              // const card_events = await API.graphql({
              //   query: queries.listNotificationsByCustomer,
              //   variables: { customer_id: store.state.customer_id, event_datetime_start: { between: [moment().format('YYYY-MM-DDTHH'), moment().add(25, 'hours').format('YYYY-MM-DDTHH')] }, limit: 1500 },
              //   authToken: store.state.lambda_auth_token
              // })
              // commit('SET_CARD_EVENTS', card_events.data.listNotificationsByCustomer.items)

              commit('SET_EVENTS', { alerts: alerts1, notices: notices1.value, warnings: warnings1.value })
            } catch (error) {
              commit('SET_EVENTS', { alerts: [], notices: [], warnings: [] })
            }


          } else {
            commit('SET_EVENTS', { alerts: [], notices: [], warnings: [] })
          }

          resolve()
        }
        get_events(params)
      })
    },

    DDB_GET_ANIMAL({ commit }, id) {
      return new Promise((resolve) => {
        async function get_animal(id) {
          var animal = null
          var offspring = null
          var measurements = { weight: [], weight_birth: [], height: [] }
          var phenotypes = { hoof: [], claw: [], udder: [], teat: [] }

          try {
            if (id && (store.state.allowed_operations.includes('AnimalRead') || store.state.allowed_operations == 'All')) {
              animal = (await API.graphql({
                query: queries.getAnimal,
                variables: { id: id },
                authToken: store.state.lambda_auth_token
              })).data.getAnimal
              if (animal.animal_sex == 'MALE') {
                let tmp_offspring = await API.graphql({
                  query: queries.listAnimalsByCustomer,
                  variables: { customer_id: store.state.customer_id, filter: { sire_id: { eq: id } }, limit: 2000 },
                  authToken: store.state.lambda_auth_token
                })
                offspring = tmp_offspring.data.listAnimalsByCustomer.items
              } else if (animal.animal_sex == 'FEMALE') {
                let tmp_offspring = await API.graphql({
                  query: queries.listAnimalsByCustomer,
                  variables: { customer_id: store.state.customer_id, filter: { dam_id: { eq: id } }, limit: 2000 },
                  authToken: store.state.lambda_auth_token
                })
                offspring = tmp_offspring.data.listAnimalsByCustomer.items
              }
            }
            // measurements.weight = (store.state.allowed_operations.includes('MeasurementRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            //   query: queries.listMeasurementsByAnimal,
            //   variables: { animal_id: id, limit: 4000, filter: { measurement_type: { eq: 'WEIGHT_MATURE' } } },
            //   authToken: store.state.lambda_auth_token
            // })).data.listMeasurementsByAnimal.items : []
            // measurements.weight_birth = (store.state.allowed_operations.includes('MeasurementRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            //   query: queries.listMeasurementsByAnimal,
            //   variables: { animal_id: id, limit: 4000, filter: { measurement_type: { eq: 'WEIGHT_BIRTH' } } },
            //   authToken: store.state.lambda_auth_token
            // })).data.listMeasurementsByAnimal.items : []
            // measurements.weight_birth = measurements.weight_birth.length ? measurements.weight_birth[0] : {}
            // measurements.height = (store.state.allowed_operations.includes('MeasurementRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            //   query: queries.listMeasurementsByAnimal,
            //   variables: { animal_id: id, limit: 4000, filter: { measurement_type: { eq: 'HEIGHT' } } },
            //   authToken: store.state.lambda_auth_token
            // })).data.listMeasurementsByAnimal.items : []
            // phenotypes.hoof = (store.state.allowed_operations.includes('PenotypeRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            //   query: queries.listPhenotypesByAnimal,
            //   variables: { animal_id: id, limit: 4000, filter: { phenotype_type: { eq: 'HOOF' } } },
            //   authToken: store.state.lambda_auth_token
            // })).data.listPhenotypesByAnimal.items : []
            // phenotypes.claw = (store.state.allowed_operations.includes('PenotypeRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            //   query: queries.listPhenotypesByAnimal,
            //   variables: { animal_id: id, limit: 4000, filter: { phenotype_type: { eq: 'CLAW' } } },
            //   authToken: store.state.lambda_auth_token
            // })).data.listPhenotypesByAnimal.items : []
            // phenotypes.udder = (store.state.allowed_operations.includes('PenotypeRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            //   query: queries.listPhenotypesByAnimal,
            //   variables: { animal_id: id, limit: 4000, filter: { phenotype_type: { eq: 'UDDER' } } },
            //   authToken: store.state.lambda_auth_token
            // })).data.listPhenotypesByAnimal.items : []
            // phenotypes.teat = (store.state.allowed_operations.includes('PenotypeRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            //   query: queries.listPhenotypesByAnimal,
            //   variables: { animal_id: id, limit: 4000, filter: { phenotype_type: { eq: 'TEAT' } } },
            //   authToken: store.state.lambda_auth_token
            // })).data.listPhenotypesByAnimal.items : []


            if (animal.measurements && animal.measurements.items && animal.measurements.items.length) {
              measurements.weight = animal.measurements.items.filter((m) => m.measurement_type.includes('WEIGHT') && m.measurement_type != 'WEIGHT_BIRTH')
              let temp_birth = animal.measurements.items.filter((m) => m.measurement_type == 'WEIGHT_BIRTH')
              measurements.weight_birth = (temp_birth[0] != undefined) ? temp_birth[0] : {}
              measurements.height = animal.measurements.items.filter((m) => m.measurement_type == 'HEIGHT')
            }

            if (animal.phenotypes && animal.phenotypes.items && animal.phenotypes.items.length) {
              phenotypes.hoof = animal.phenotypes.items.filter((p) => p.phenotype_type == 'HOOF')
              phenotypes.claw = animal.phenotypes.items.filter((p) => p.phenotype_type == 'CLAW')
              phenotypes.udder = animal.phenotypes.items.filter((p) => p.phenotype_type == 'UDDER')
              phenotypes.teat = animal.phenotypes.items.filter((p) => p.phenotype_type == 'TEAT')
            }

            var medical_records = []

            let temp_records = (
              await API.graphql({
                query: queries.listTreatmentsByAnimal,
                variables: { animal_id: id },
                authToken: store.state.lambda_auth_token,
              })
            ).data.listTreatmentsByAnimal.items

            for (const i in temp_records) {
              medical_records.push(temp_records[i].treatment)
            }



          } catch (err) {
            console.log(err)
          }


          commit('SET_ANIMAL', [animal, offspring, measurements, phenotypes, medical_records])
          resolve()
        }
        get_animal(id)
      })
    },
    DDB_GET_CUSTOMER_DATA({ commit, dispatch }) {
      return new Promise((resolve) => {
        function ao(o) {
          return store.state.allowed_operations.includes(o) || store.state.allowed_operations == 'All'
        }
        async function get_customer_data() {
          // dispatch('DDB_GET_ENUMS')

          if (store.state.allowed_operations == 'All') {

            let customer = (await API.graphql({
              query: queries.getCustomer,
              variables: {
                id: store.state.user.username
              },
              authToken: store.state.lambda_auth_token_home
            })).data.getCustomer
            let collaborator_companies = []

            let elanco_customer = (store.state.user.username == process.env.VUE_APP_ELANCO_ACC) ? true : false
            if (customer) {
              for (const i in customer.customer_is_collaborator) {
                if (customer.customer_is_collaborator[i].id == process.env.VUE_APP_ELANCO_ACC && !elanco_customer) {

                  elanco_customer = true
                }
                const user = await Auth.currentAuthenticatedUser()

                const token = user.getSignInUserSession().getIdToken().jwtToken;
                const lambda_auth_token = 'Bearer ' + btoa(customer.customer_is_collaborator[i].id) + '.' + token;
                var company = '';

                try {
                  company = (await API.graphql({
                    query: queries.getCollabCustomerCompanyName,
                    variables: {
                      id: customer.customer_is_collaborator[i].id
                    },
                    authToken: lambda_auth_token
                  })).data.getCustomer.customer_company_name
                } catch {
                  company = customer.customer_is_collaborator[i].email;
                }
                collaborator_companies.push(company)
              }
              if (!elanco_customer) {
                dispatch("logout")
                commit('SET_LOGIN_ERROR', 'Your account is not in collaboration with Elanco')

                return false
              }
            } else {
              commit('SET_LOGIN_ERROR', "Something went wrong with your account, please contact support")
            }


            commit('SET_USER_DATA', { customer: customer, collaborator_companies: collaborator_companies })
          }


          // let jetsons = await API.graphql({
          //   query: queries.listJetsonsByCustomer,
          //   variables: {
          //     customer_id: store.state.user.username
          //   },
          //   authToken: store.state.lambda_auth_token
          // })
          // commit('SET_JETSON', jetsons.data.listJetsonsByCustomer.items)

          let cameras_p = ao('CameraRead') ? (await API.graphql({
            query: queries.listCamerasByCustomer,
            variables: {
              customer_id: store.state.customer_id
            },
            authToken: store.state.lambda_auth_token
          })).data.listCamerasByCustomer.items : []

          let wifis_p = ao('WifiRead') ? (await API.graphql({
            query: queries.listWifiByCustomer,
            variables: {
              customer_id: store.state.customer_id
            },
            authToken: store.state.lambda_auth_token
          })).data.listWifiByCustomer.items : []


          let zones_p = ao('ZoneRead') ? (await API.graphql({
            query: queries.listZonesByCustomer,
            variables: {
              customer_id: store.state.customer_id
            },
            authToken: store.state.lambda_auth_token
          })).data.listZonesByCustomer.items : []


          let animals_p = ao('AnimalRead') ? (await API.graphql({
            query: queries.listAnimalsByCustomer,
            variables: { customer_id: store.state.customer_id, limit: 2000 },
            authToken: store.state.lambda_auth_token
          })).data.listAnimalsByCustomer.items : []


          let events_p = ao('EventRead') ? (await API.graphql({
            query: queries.listEventsByCustomer,
            variables: { customer_id: store.state.customer_id, limit: 1500 },
            authToken: store.state.lambda_auth_token
          })).data.listEventsByCustomer.items : []
          var calendar_ready_events = []



          let treatments_p = ao('TreatmentRead') ? (await API.graphql({
            query: queries.listTreatmentsByCustomer,
            variables: {
              limit: 4000,
              customer_id: store.state.customer_id
            },
            authToken: store.state.lambda_auth_token
          })).data.listTreatmentsByCustomer.items : []


          let measurements_p = ao('MeasurementRead') ? (await API.graphql({
            query: queries.listMeasurementsByCustomer,
            variables: {
              limit: 4000,
              customer_id: store.state.customer_id
            },
            authToken: store.state.lambda_auth_token
          })).data.listMeasurementsByCustomer.items : []


          let phenotypes_p = ao('PhenotypeRead') ? (await API.graphql({
            query: queries.listPhenotypesByCustomer,
            variables: {
              limit: 4000,
              customer_id: store.state.customer_id
            },
            authToken: store.state.lambda_auth_token
          })).data.listPhenotypesByCustomer.items : []

          let site_p = ao('SiteRead') ? (await API.graphql({
            query: queries.listSitesByCustomer,
            variables: {
              customer_id: store.state.customer_id
            },
            authToken: store.state.lambda_auth_token
          })).data.listSitesByCustomer.items : []

          let [cameras, wifis, zones, animals, events, treatments, measurements, phenotypes, site] = await Promise.allSettled([cameras_p, wifis_p, zones_p, animals_p, events_p, treatments_p, measurements_p, phenotypes_p, site_p]);
          for (const i in events.value) {
            calendar_ready_events.push({
              id: events.value[i].id,
              name: events.value[i].event_title,
              start: moment(events.value[i].event_datetime_start, "YYYY-MM-DDTHH:mm:ss.sssZ").format("YYYY-MM-DD"),
              end: events.value[i].event_datetime_end ? moment(events.value[i].event_datetime_end, "YYYY-MM-DDTHH:mm:ss.sssZ").format("YYYY-MM-DD") : '',
              color: events.value[i].event_color,
              timed: events.value[i].event_datetime_end ? false : true,
            })
          }
          commit('SET_PHENOTYPES', phenotypes.value)
          commit('SET_MEASUREMENTS', measurements.value)
          commit('SET_MEDICAL_RECORDS', treatments.value)
          // commit('SET_CALENDAR_EVENTS', calendar_ready_events.value)
          commit('SET_CAMERA_OBJECTS', sort_by_key(cameras.value, 'camera_name'))
          commit('SET_WIFI_OBJECTS', wifis.value)
          commit('SET_ZONES', sort_by_key(zones.value, 'zone_name'))
          commit('SET_FILTERED_ANIMALS', animals.value)
          if (animals.value) {
            commit('SET_DAM_SIRES', dam_sires(animals.value))
          }

          commit('SET_ANIMALS', animals.value)
          commit('SET_SITE', site.value)

          if (site.value.length > 0 && site.value[0]) {
            if (site.value[0].site_location) {
              dispatch('GET_WEATHER_DATA', site.value[0].site_location)
            } else {
              dispatch('GET_WEATHER_DATA', { latitude: null, longitude: null })
            }
          }
          eventBus.$emit("update_home_chats")
          resolve()
        }
        get_customer_data()
      })
    },
    DDB_GET_ENUMS({ commit }) {
      return new Promise((resolve) => {
        function to_items(array) {
          var temp_array = []
          for (const i in array) {
            if (array[i].name != "BCS") {
              temp_array.push({ value: array[i].name, text: (array[i].name.charAt(0) + array[i].name.slice(1).toLowerCase()).replaceAll('_', ' ') })
            } else {
              temp_array.push({ value: array[i].name, text: array[i].name })
            }
          }
          return temp_array
        }
        async function get_enums() {
          var temp_enums_items = {}
          try {
            let tag_color_p = (await API.graphql({
              query: queries.listTagColors,
              authToken: store.state.lambda_auth_token_home
            })).data.__type.enumValues

            let production_type_p = (await API.graphql({
              query: queries.listProductionType,
              authToken: store.state.lambda_auth_token_home
            })).data.__type.enumValues

            let sex_classification_p = (await API.graphql({
              query: queries.listSexClassification,
              authToken: store.state.lambda_auth_token_home
            })).data.__type.enumValues

            let animal_purpose_p = (await API.graphql({
              query: queries.listAnimalPurpose,
              authToken: store.state.lambda_auth_token_home
            })).data.__type.enumValues

            let ownership_status_p = (await API.graphql({
              query: queries.listOwnershipStatus,
              authToken: store.state.lambda_auth_token_home
            })).data.__type.enumValues

            let birth_ease_p = (await API.graphql({
              query: queries.listBirthEase,
              authToken: store.state.lambda_auth_token_home
            })).data.__type.enumValues

            let nurse_info_p = (await API.graphql({
              query: queries.listNurseInfo,
              authToken: store.state.lambda_auth_token_home
            })).data.__type.enumValues

            let dam_status_p = (await API.graphql({
              query: queries.listDamStatus,
              authToken: store.state.lambda_auth_token_home
            })).data.__type.enumValues

            let treatment_type_p = (await API.graphql({
              query: queries.listTreatmentType,
              authToken: store.state.lambda_auth_token_home
            })).data.__type.enumValues

            let drug_administration_type_p = (await API.graphql({
              query: queries.listDrugAdministrationType,
              authToken: store.state.lambda_auth_token_home
            })).data.__type.enumValues

            let drug_type_p = (await API.graphql({
              query: queries.listDrugType,
              authToken: store.state.lambda_auth_token_home
            })).data.__type.enumValues


            let measurement_type_p = (await API.graphql({
              query: queries.listMeasurementType,
              authToken: store.state.lambda_auth_token_home
            })).data.__type.enumValues


            let phenotype_type_p = (await API.graphql({
              query: queries.listPhenotypeType,
              authToken: store.state.lambda_auth_token_home
            })).data.__type.enumValues


            let zone_type_p = (await API.graphql({
              query: queries.listZoneType,
              authToken: store.state.lambda_auth_token_home
            })).data.__type.enumValues


            let event_type_p = (await API.graphql({
              query: queries.listEventType,
              authToken: store.state.lambda_auth_token_home
            })).data.__type.enumValues

            let animal_status_p = (await API.graphql({
              query: queries.listAnimalStatus,
              authToken: store.state.lambda_auth_token_home
            })).data.__type.enumValues

            let [tag_color, production_type, sex_classification, animal_purpose, ownership_status, birth_ease, nurse_info, dam_status, treatment_type, drug_administration_type, drug_type, measurement_type, phenotype_type, zone_type, event_type, animal_status] = await Promise.allSettled([tag_color_p, production_type_p, sex_classification_p, animal_purpose_p, ownership_status_p, birth_ease_p, nurse_info_p, dam_status_p, treatment_type_p, drug_administration_type_p, drug_type_p, measurement_type_p, phenotype_type_p, zone_type_p, event_type_p, animal_status_p]);
            temp_enums_items.phenotype_type = to_items(phenotype_type.value)
            temp_enums_items.measurement_type = to_items(measurement_type.value)
            temp_enums_items.drug_type = to_items(drug_type.value)
            temp_enums_items.drug_administration_type = to_items(drug_administration_type.value)
            temp_enums_items.treatment_type = to_items(treatment_type.value)
            temp_enums_items.dam_status = to_items(dam_status.value)
            temp_enums_items.nurse_info = to_items(nurse_info.value)
            temp_enums_items.ownership_status = to_items(ownership_status.value)
            temp_enums_items.animal_purpose = to_items(animal_purpose.value)
            temp_enums_items.sex_classification = to_items(sex_classification.value)
            temp_enums_items.production_type = to_items(production_type.value)
            temp_enums_items.tag_color = to_items(tag_color.value)
            temp_enums_items.birth_ease = to_items(birth_ease.value)
            temp_enums_items.zone_type = to_items(zone_type.value)
            temp_enums_items.event_type = to_items(event_type.value)
            temp_enums_items.animal_status = to_items(animal_status.value)


            commit('SET_ENUMS', temp_enums_items)

          } catch {
            return false
          }

          resolve()
        }
        get_enums()
      })
    },
    DDB_GET_CUSTOMER({ commit }) {
      return new Promise((resolve) => {
        async function get_site() {
          let customer = (await API.graphql({
            query: queries.getCustomer,
            variables: {
              id: store.state.user.username
            },
            authToken: store.state.lambda_auth_token_home
          })).data.getCustomer
          let collaborator_companies = []
          for (const i in customer.customer_is_collaborator) {
            const user = await Auth.currentAuthenticatedUser()

            const token = user.getSignInUserSession().getIdToken().jwtToken;
            const lambda_auth_token = 'Bearer ' + btoa(customer.customer_is_collaborator[i].id) + '.' + token;
            var company = '';
            try {
              company = (await API.graphql({
                query: queries.getCollabCustomerCompanyName,
                variables: {
                  id: customer.customer_is_collaborator[i].id
                },
                authToken: lambda_auth_token
              })).data.getCustomer.customer_company_name
            } catch {
              company = customer.customer_is_collaborator[i].email;

            }
            collaborator_companies.push(company)
          }

          commit('SET_USER_DATA', { customer: customer, collaborator_companies: collaborator_companies })
          resolve()
        }
        get_site()
      })
    },
    DDB_GET_SITES({ commit }) {
      return new Promise((resolve) => {
        async function get_site() {
          let site = (store.state.allowed_operations.includes('SiteRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            query: queries.listSitesByCustomer,
            variables: {
              customer_id: store.state.customer_id
            },
            authToken: store.state.lambda_auth_token
          })).data.listSitesByCustomer.items : []
          commit('SET_SITE', site)
          resolve()
        }
        get_site()
      })
    },


    DDB_GET_ANIMALS({ commit }, params) {
      return new Promise((resolve) => {

        async function get_animals() {
          if (store.state.allowed_operations.includes('AnimalRead') || store.state.allowed_operations == 'All') {
            var animals = []
            if (params && params.herd && params.herd != 'all' && (!params.group || params.group == 'all')) {
              animals = (await API.graphql({
                query: queries.listAnimalsByHerd,
                variables: { herd_id: params.herd },
                authToken: store.state.lambda_auth_token
              })).data.listAnimalsByHerd.items

            } else if (params && params.group && params.group != 'all' && (!params.herd || params.herd == 'all')) {
              let temp_animals = (await API.graphql({
                query: queries.getGroup,
                variables: { id: params.group },
                authToken: store.state.lambda_auth_token
              })).data.getGroup.animals.items
              for (const i in temp_animals) {
                animals.push(temp_animals[i].animal)
              }

            } else {
              animals = (await API.graphql({
                query: queries.listAnimalsByCustomer,
                variables: { customer_id: store.state.customer_id, limit: 2000 },
                authToken: store.state.lambda_auth_token
              })).data.listAnimalsByCustomer.items

            }

            let all_animals = (await API.graphql({
              query: queries.listAnimalsByCustomer,
              variables: { customer_id: store.state.customer_id, limit: 2000 },
              authToken: store.state.lambda_auth_token
            })).data.listAnimalsByCustomer.items

            if (all_animals) {
              commit('SET_DAM_SIRES', dam_sires(all_animals))
            }
            //  set_phenotypes(animals)
            commit('SET_FILTERED_ANIMALS', animals)
            commit('SET_ANIMALS', all_animals)


            resolve()
          }
        }
        get_animals()
      })
    },

    DDB_GET_MEDICAL_RECORDS({ commit }, params) {
      return new Promise((resolve) => {
        async function get_medical_records() {

          if (store.state.allowed_operations.includes('TreatmentRead') || store.state.allowed_operations == 'All') {
            if (params && params.herd && params.herd != 'all' && (!params.group || params.group == 'all')) {
              var herd_treatments = []
              let treatments = await API.graphql({
                query: queries.listTreatmentsByHerd,
                variables: {
                  limit: 4000,
                  herd_id: params.herd
                },
                authToken: store.state.lambda_auth_token
              })
              for (const i in treatments.data.listAnimalsByHerd.items) {
                herd_treatments = herd_treatments.concat(treatments.data.listAnimalsByHerd.items[i].treatments.items)
              }
              commit('SET_MEDICAL_RECORDS', herd_treatments)
            } else {
              let treatments = await API.graphql({
                query: queries.listTreatmentsByCustomer,
                variables: {
                  limit: 4000,
                  customer_id: store.state.customer_id
                },
                authToken: store.state.lambda_auth_token
              })
              commit('SET_MEDICAL_RECORDS', treatments.data.listTreatmentsByCustomer.items)
            }
          }
          resolve()
        }
        get_medical_records()
      })
    },

    DDB_GET_MEASUREMENT({ commit }, params) {
      return new Promise((resolve) => {
        async function get_weight_records() {
          var chart_measurements = { b_w: { weights: [], tags: [], animals: [] }, w_w: [[], [], [], [], [], [], [], [], [], [], [], []], y_w: [[], [], [], [], [], [], [], [], [], []], m_w: [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []] }
          var temp_b_w = []
          var temp_w_w = []
          var temp_y_w = []
          var temp_m_w = []
          if (store.state.allowed_operations.includes('MeasurementRead') || store.state.allowed_operations == 'All') {
            var measurements = []
            if (params && params.herd && params.herd != 'all' && (!params.group || params.group == 'all')) {
              let herd_measurements = await API.graphql({
                query: queries.listMeasurementsByHerd,
                variables: {
                  limit: 4000,
                  herd_id: params.herd
                },
                authToken: store.state.lambda_auth_token
              })
              for (const i in herd_measurements.data.listAnimalsByHerd.items) {
                measurements = measurements.concat(herd_measurements.data.listAnimalsByHerd.items[i].measurements.items)
              }
            }
            else {
              measurements = (await API.graphql({
                query: queries.listMeasurementsByCustomer,
                variables: {
                  limit: 4000,
                  customer_id: store.state.customer_id
                },
                authToken: store.state.lambda_auth_token
              })).data.listMeasurementsByCustomer.items
            }
          }
          temp_b_w = measurements.filter(measurement => (measurement.measurement_type == 'WEIGHT_BIRTH' && measurement.animal && measurement.animal.animal_classification == 'CALF'))
          temp_w_w = measurements.filter(measurement => measurement.measurement_type == 'WEIGHT_WEANING')
          temp_y_w = measurements.filter(measurement => measurement.measurement_type == 'WEIGHT_YEARLING')
          temp_m_w = measurements.filter(measurement => measurement.measurement_type == 'WEIGHT_MATURE')
          var index = 0
          for (const i in temp_b_w) {

            chart_measurements.b_w.weights[index].push(temp_b_w[i] ? temp_b_w[i].measurement_value : 0)
            chart_measurements.b_w.tags[index].push(temp_b_w[i].animal.tags.items[0] ? temp_b_w[i].animal.tags.items[0] : '-')
            chart_measurements.b_w.animals[index].push(temp_b_w[i].animal)
          }
          for (const i in temp_w_w) {
            if (temp_w_w[i].measurement_value > 200 && temp_w_w[i].measurement_value < 1200) {
              index = Math.ceil((temp_w_w[i].measurement_value - 200) / 100)
            } else if (temp_w_w[i].measurement_value > 1200) {
              index = 11
            }
            chart_measurements.w_w[index].push(temp_w_w[i])
          }
          for (const i in temp_y_w) {
            if (temp_y_w[i].measurement_value > 800 && temp_y_w[i].measurement_value < 1600) {
              index = Math.ceil((temp_y_w[i].measurement_value - 800) / 100)
            } else if (temp_y_w[i].measurement_value > 1600) {
              index = 9
            }
            chart_measurements.y_w[index].push(temp_y_w[i])
          }
          for (const i in temp_m_w) {
            if (temp_m_w[i].measurement_value > 900 && temp_m_w[i].measurement_value < 2500) {
              index = Math.ceil((temp_m_w[i].measurement_value - 900) / 100)
            } else if (temp_m_w[i].measurement_value > 2500) {
              index = 17
            }
            chart_measurements.m_w[index].push(temp_m_w[i])
          }
          commit('SET_MEASUREMENT_CHART', chart_measurements)
          commit('SET_MEASUREMENTS', measurements)
          resolve()
        }
        get_weight_records()
      })
    },
    DDB_GET_PHENOTYPES({ commit }, params) {
      return new Promise((resolve) => {

        async function get_phenotypes() {

          if (store.state.allowed_operations.includes('PhenotypeRead') || store.state.allowed_operations == 'All') {
            var phenotypes = []
            var temp_claw = []
            var temp_hoof = []
            var temp_teat = []
            var temp_udder = []
            var chart_phenotype = { hoof: [[], [], [], [], [], [], [], [], []], claw: [[], [], [], [], [], [], [], [], []], udder: [[], [], [], [], [], [], [], [], []], teat: [[], [], [], [], [], [], [], [], []] }
            if (params && params.herd && params.herd != 'all' && (!params.group || params.group == 'all')) {

              let herd_phenotypes = await API.graphql({
                query: queries.listPhenotypesByHerd,
                variables: {
                  limit: 4000,
                  herd_id: params.herd
                },
                authToken: store.state.lambda_auth_token
              })
              for (const i in herd_phenotypes.data.listAnimalsByHerd.items) {
                phenotypes = phenotypes.concat(herd_phenotypes.data.listAnimalsByHerd.items[i].phenotypes.items)
              }



            } else {
              phenotypes = (await API.graphql({
                query: queries.listPhenotypesByCustomer,
                variables: {
                  limit: 4000,
                  customer_id: store.state.customer_id
                },
                authToken: store.state.lambda_auth_token
              })).data.listPhenotypesByCustomer.items
            }
            temp_claw = phenotypes.filter(phenotype => phenotype.phenotype_type == 'CLAW')
            temp_hoof = phenotypes.filter(phenotype => phenotype.phenotype_type == 'HOOF')
            temp_teat = phenotypes.filter(phenotype => phenotype.phenotype_type == 'TEAT')
            temp_udder = phenotypes.filter(phenotype => phenotype.phenotype_type == 'UDDER')
            for (const i in temp_claw) {
              chart_phenotype.claw[temp_claw[i].phenotype_score - 1].push(temp_claw[i])
            }
            for (const i in temp_hoof) {
              chart_phenotype.hoof[temp_hoof[i].phenotype_score - 1].push(temp_hoof[i])
            }
            for (const i in temp_teat) {
              chart_phenotype.teat[temp_teat[i].phenotype_score - 1].push(temp_teat[i])
            }
            for (const i in temp_udder) {
              chart_phenotype.udder[temp_udder[i].phenotype_score - 1].push(temp_udder[i])
            }
            commit('SET_PHENOTYPE_CHART', chart_phenotype)
            commit('SET_PHENOTYPES', phenotypes)
          }
          resolve()
        }
        get_phenotypes()
      })
    },


    DDB_GET_CUSTOMER_CAMERAS({ commit }) {
      return new Promise((resolve) => {
        async function get_customer_cameras() {
          let cameras = (store.state.allowed_operations.includes('CameraRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            query: queries.listCamerasByCustomer,
            variables: {
              customer_id: store.state.customer_id
            },
            authToken: store.state.lambda_auth_token
          })).data.listCamerasByCustomer.items : []
          commit('SET_CAMERA_OBJECTS', sort_by_key(cameras, 'camera_name'))
          resolve()
        }
        get_customer_cameras()
      })
    },
    DDB_GET_CUSTOMER_WIFIS({ commit }) {
      return new Promise((resolve) => {
        async function get_customer_cameras() {

          let wifis = (store.state.allowed_operations.includes('WifiRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            query: queries.listWifiByCustomer,
            variables: {
              customer_id: store.state.customer_id
            },
            authToken: store.state.lambda_auth_token
          })).data.listWifiByCustomer.items : []
          commit('SET_WIFI_OBJECTS', wifis)
          resolve()
        }
        get_customer_cameras()
      })
    },
    DDB_GET_CUSTOMER_JETSONS({ commit }) {
      return new Promise((resolve) => {
        async function get_customer_cameras() {

          let jetsons = (store.state.allowed_operations.includes('JetsonRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            query: queries.listJetsonsByCustomer,
            variables: {
              customer_id: store.state.customer_id
            },
            authToken: store.state.lambda_auth_token
          })).data.listJetsonsByCustomer.items : []
          commit('SET_JETSON', jetsons)
          resolve()
        }
        get_customer_cameras()
      })
    },
    DDB_GET_SELECTED_CAMERA({ commit }, id) {
      return new Promise((resolve) => {

        async function get_camera(id) {

          var camera = (store.state.allowed_operations.includes('CameraRead') || store.state.allowed_operations == 'All') ? (await API.graphql({
            query: queries.getCamera,
            variables: { id: id },
            authToken: store.state.lambda_auth_token
          })).data.getCamera : []
          commit('SET_SELECTED_CAMERA', camera)
          resolve()
        }
        get_camera(id)
      })
    },

    DDB_GET_THUMBNAIL_INFO({ commit }, params) {
      return new Promise((resolve) => {
        //var _this = this
        var thumbnail_objects = []
        async function get_videos() {
          store.state.video_loading = true
          var start_datetime;
          var end_datetime;
          if (Array.isArray(params.dates)) {
            start_datetime = params.dates[0];
            end_datetime = params.dates[1];
          } else {
            start_datetime = params.dates;
            end_datetime = moment(params.dates, 'YYYY-MM-DDTHH').add(25, 'hours').format('YYYY-MM-DDTHH');
          }

          if (store.state.allowed_operations.includes('VideoRead') || store.state.allowed_operations == 'All') {
            let usable_cameras = [
              "ELA21072940807K4",
              "ELA21072981009K8",
              "ELA21072971211K7",
              "ELA21072930605K3",
              "ELA21072961413K6",
              "ELA21072951615K5",
              "ELA21072910201K1",
              "ELA21072920403K2",
              "ELA22103710201K1",
              "ELA22103720403K2",
              "ELA22103730605K3",
              "ELA22103740807K4",
              "ELA22103781009K8",
              "ELA22103771211K7",
              "ELA22103761413K6",
              "ELA22103751615K5"
            ]
            var cameras_query_param = []

            for (const i in usable_cameras) {
              cameras_query_param[i] = { camera_id: { eq: usable_cameras[i] } }
            }
            if (!params.selected_bookmark) {
              if (!params.cam_arr) {

                thumbnail_objects = await API.graphql({
                  query: queries.listVideosByCustomer,
                  variables: { customer_id: store.state.customer_id, filter: { or: cameras_query_param }, video_datetime: { between: [start_datetime, end_datetime] }, limit: 999, sortDirection: params.sort_order },
                  authToken: store.state.lambda_auth_token
                })
                thumbnail_objects = thumbnail_objects.data.listVideosByCustomer.items
              } else {
                thumbnail_objects = await API.graphql({
                  query: queries.listVideosByCustomerCameraDate,
                  variables: { customer_id: store.state.customer_id, camera_idVideo_datetime: { between: [{ camera_id: params.cam_arr, video_datetime: start_datetime }, { camera_id: params.cam_arr, video_datetime: end_datetime }], }, limit: 999, sortDirection: params.sort_order },
                  authToken: store.state.lambda_auth_token
                })
                thumbnail_objects = thumbnail_objects.data.listVideosByCustomerCameraDate.items
              }
            } else {
              if (!params.cam_arr) {
                thumbnail_objects = await API.graphql({
                  query: queries.listVideosByCustomer,
                  variables: { customer_id: store.state.customer_id, video_datetime: { between: [start_datetime, end_datetime] }, limit: 1500, sortDirection: params.sort_order, filter: { or: cameras_query_param, video_bookmarked: { eq: 'YES' } } },
                  authToken: store.state.lambda_auth_token
                })
                thumbnail_objects = thumbnail_objects.data.listVideosByCustomer.items
              } else {
                thumbnail_objects = await API.graphql({
                  query: queries.listVideosByCustomerCameraDate,
                  variables: { customer_id: store.state.customer_id, camera_idVideo_datetime: { between: [{ camera_id: params.cam_arr, video_datetime: start_datetime }, { camera_id: params.cam_arr, video_datetime: end_datetime }], }, limit: 1500, sortDirection: params.sort_order, filter: { video_bookmarked: { eq: 'YES' } } },
                  authToken: store.state.lambda_auth_token
                })
                thumbnail_objects = thumbnail_objects.data.listVideosByCustomerCameraDate.items
              }
            }
            thumbnail_objects.sort((a, b) => a.camera_id.localeCompare(b.camera_id) || moment(a.video_datetime, "YYYY-MM-DDTHH:mm:ss.sssZ").diff(moment(b.video_datetime, "YYYY-MM-DDTHH:mm:ss.sssZ"))
            )

          }

          store.state.video_loading = false
          commit('SET_THUMBNAILS', thumbnail_objects)

          resolve()
        }

        get_videos()
      })
    },

    //  Authentication

    async login({ dispatch, state }, { email, password, temp_password }) {
      state.loginError = ''

      try {
        await Auth.signIn(email, (temp_password ? temp_password : password)).then(async function (user) {
          if (user.challengeName == "NEW_PASSWORD_REQUIRED" && !temp_password) {
            throw new Error("NEW_PASSWORD_REQUIRED")
          } else if (user.challengeName == "NEW_PASSWORD_REQUIRED" && temp_password) {
            Auth.completeNewPassword(
              user,               // the Cognito User Object
              password,       // the new password
              // OPTIONAL, the required attributes

            ).then(async function () {
              // at this time the user is logged in if no MFA required

              await dispatch('fetchUser')

            }).catch(e => {
              console.log(e)

            });
          }
          else {
            await dispatch('fetchUser')
          }

        });
      } catch (err) {
        if (err)
          state.button = true
        state.loginError = err.message || err
        return
      }

    },



    async authState({ commit, dispatch }, state) {
      if (state === 'signedIn') {
        await dispatch('fetchUser')
      }

      else { commit('user', null) }

    },

    async fetchUser({ commit, dispatch, state }) {
      try {

        const user = await Auth.currentAuthenticatedUser()

        const expires = user.getSignInUserSession().getIdToken().payload.exp - Math.floor(new Date().getTime() / 1000)
        //const group = user.getSignInUserSession().getIdToken().decodePayload()["cognito:groups"]

        // console.log(`Token expires in ${expires} seconds`)
        // if (group.includes('Admin')) {
        //   throw "User has Administrator access, please contact OneCup suppoort."
        // }
        setTimeout(async () => {
          //console.log('Renewing Token')
          await dispatch('fetchUser')
        }, expires * 1000)

        commit('user', user)
        // set cookies
        const token = user.getSignInUserSession().getIdToken().jwtToken;
        initializeLambdaClient().then((client) => {
          this.state.lambda_client = client;
        });
        commit('SET_JWT_TOKEN', token)
        const lambda_auth_token = 'Bearer ' + btoa(user.username) + '.' + token;
        commit('SET_LAMBDA_AUTH_TOKEN', lambda_auth_token)
        commit('SET_LAMBDA_AUTH_TOKEN_HOME', lambda_auth_token)
        var config = {
          method: 'GET',
          withCredentials: true,
          url: process.env.VUE_APP_COOKIE_URL,
          headers: {
            Auth: `Bearer ${token}`,
            Owner: user.username
          },
        };

        axios(config)
          .then(function (response) {
            console.log(response);

          })
          .catch(function (error) {
            console.log(error);
          });

        if (user) {
          eventBus.$emit("fetched_user")
          commit('SET_CUSTOMER_ID', user.username)
          commit('SET_ALLOWED_OPERATIONS', 'All')
          await API.graphql(
            graphqlOperation(subscriptions.onCreateNotification, { owner: user.username }, store.state.lambda_auth_token)
          ).subscribe({
            next: () => dispatch('DDB_GET_NOTIFICATIONS', this.state.event_params),
            error: (error) => console.log(error.error.errors[0])
          });
        }
        //authToken: store.state.lambda_auth_token
      } catch (err) {
        state.loginError = err
        commit('user', null)
        return false
      }
    },

    async logout({ commit }) {

      await Auth.signOut();
      Object.keys(this.state).forEach(key => {
        this.state[key] = initial_store[key]
      })
      commit('user', null)
    },

  },

  getters: {
    getCameraTotalDogTrials(state) {
      return state.camera_trials_dog_total;
    },
    getHomeScratchingGraphs(state) {
      return state.home_scratching_graphs;
    },
    getScratchingTrial(state) {
      return state.scratching_trial;
    },
    getDamsSires(state) {
      return state.dams_sires;
    },
    getPendingAnimalIdendifications(state) {
      return state.pending_animal_identifications;
    },
    getAnalyticsPredators(state) {
      return state.analytics_predator;
    },
    getAnalyticsContractions(state) {
      return state.analytics_contraction;
    },
    getAnalyticsAnimalCount(state) {
      return state.analytics_animal_count;
    },
    getAnalyticsAnimalImageCount(state) {
      return state.analytics_animal_image_count;
    },
    getAnalyticsAnimalImageByPGIECount(state) {
      return state.analytics_animal_image_by_pgie_count;
    },
    getAnalyticsAnimalsByPGIECount(state) {
      return state.analytics_animals_by_pgie_count;
    },
    getAnalyticsContractionsByPGIECount(state) {
      return state.analytics_contractions_by_pgie_count;
    },
    getAnalyticsPredatorsByPGIECount(state) {
      return state.analytics_predators_by_pgie_count;
    },
    getBBOXColorCode(state) {
      return state.bbox_color_code;
    },
    getImages(state) {
      return state.images
    },
    getEnums(state) {
      return state.enums
    },
    getVideosAtDates(state) {
      return state.videos_at_dates
    },
    getAllowedOperations(state) {
      return state.allowed_operations
    },
    getCustomerID(state) {
      return state.customer_id
    },
    getJwtToken(state) {
      return state.jwt_token
    },
    getLambdaAuthGoken(state) {
      return state.lambda_auth_token
    },
    getLambdaAuthTokenHome(state) {
      return state.lambda_auth_token_home
    },
    getCardEvents(state) {
      return state.card_events
    },
    getLoadingModel(state) {
      return state.loading_model
    },
    getVideoLoading(state) {
      return state.video_loading
    },
    getMeasurementsCharts(state) {
      return state.measurement_charts
    },
    getPhenotypesCharts(state) {
      return state.phenotype_charts
    },
    getScrotumCircumference(state) {
      return state.scrotum_circumference
    },
    getSpermMorphology(state) {
      return state.sperm_morphology
    },
    getBulls(state) {
      return state.bulls
    },
    getCows(state) {
      return state.cows
    },
    getCalves(state) {
      return state.calves
    },
    getCalendarEvents(state) {
      return state.calendar_events
    },
    getMostRecentAnimals(state) {
      return state.most_recent_animals
    },
    getZones(state) {
      return state.zones
    },
    getAnimalGroups(state) {
      return state.animal_groups
    },
    getGroups(state) {
      return state.groups
    },
    getHerds(state) {
      return state.herds
    },
    getNotes(state) {
      return state.notes
    },
    getAnimalStats(state) {
      return state.animal_stats
    },
    getAnimal(state) {
      return state.animal
    },
    getCarcasses(state) {
      return state.carcasses
    },
    getWeightRecords(state) {
      return state.measurements
    },
    getPhenotypes(state) {
      return state.phenotypes
    },
    getMedicalRecords(state) {
      return state.medical_records
    },
    getLastFootage(state) {
      return state.last_footage
    },

    getAnimals(state) {
      return state.animals
    },
    getFilteredAnimals(state) {
      return state.filtered_animals
    },
    getWifi(state) {
      return state.wifi_object
    },

    getCoordinates(state) {
      return state.coordinates;
    },
    getJetson(state) {
      return state.jetson
    },
    getSite(state) {
      return state.site
    },
    getCameraObjects(state) {
      return state.camera_objects
    },
    getSelectedCamera(state) {
      return state.selected_camera
    },
    getUser(state) {
      return state.user;
    },
    getUserDDB(state) {
      return state.user_ddb;
    },
    getCameras(state) {
      return state.cameras;
    },
    getCameraTypes(state) {
      return state.cam_types
    },
    getCameraNames(state) {
      return state.cam_names;
    },
    getVideoList(state) {
      return state.video_list;
    },
    getThumbnails(state) {
      return state.thumbnails;
    },
    getEvents(state) {
      return state.events;
    },
    getAlerts(state) {
      return state.alerts;
    },
    getNotices(state) {
      return state.notices;
    },
    // for displaying a single cow

  },


});


//const month = moment().format('YYYY-MM-DD')
// if (store.state.user) {

// }

const initial_store = { ...store.state }

export default store;
